import React, { useEffect, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { catchHandler } from '@peakon/app-manager/actions/NotificationActions';
import {
  decodeToken,
  hasProxyViewRight,
} from '@peakon/app-manager/utils/decodeToken';
import {
  useAppDispatch,
  useAppSelector,
} from '@peakon/app-manager/utils/reduxHooks';
import { type Employee } from '@peakon/records/index';
import api from '@peakon/shared/utils/api';
import {
  useInitialAuthToken,
  useAuthToken,
} from '@peakon/shell/hooks/useTokenFromStorage';

import { ProxyViewContext } from '../contexts/ProxyViewContext';

type Props = {
  children: React.ReactNode;
};

export const ProxyViewProvider: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showProxyViewModal, setShowProxyViewModal] = useState(false);
  const [showCloseProxyViewModal, setCloseProxyViewModal] = useState(false);
  const currentSession = useAppSelector((state) => state.session);
  const currentEmployees = useAppSelector((state) => state.employees);
  const isProxyViewScopeGroup = currentSession.scopeGroup === 'proxyView';

  const [proxyViewId, setProxyViewId] = useState<string | null | undefined>(
    null,
  );
  const isOnProxyViewMode = Boolean(isProxyViewScopeGroup && proxyViewId);

  const currentEmployee =
    currentEmployees?.get(
      // @ts-expect-error Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
      proxyViewId,
    ) ?? null;
  const proxyViewManager =
    isProxyViewScopeGroup && currentEmployee && proxyViewId
      ? {
          id: proxyViewId,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          name: (currentEmployee as Employee).name,
        }
      : null;

  const [initialToken, setInitialToken] = useInitialAuthToken();
  const [authToken] = useAuthToken();
  const decodedToken = decodeToken(authToken);
  const proxyViewRight = hasProxyViewRight(authToken);

  const removeProxyView = useCallback(async () => {
    await api.delete('/auth');

    if (initialToken) {
      api.auth.authenticate(initialToken, { remember: true });
      setInitialToken(null);
    }
  }, [initialToken, setInitialToken]);

  useEffect(() => {
    if (initialToken) {
      setProxyViewId(authToken ? decodedToken?.employeeId : null);
    } else {
      setProxyViewId(null);

      // If the user is on proxy view mode and initial_auth_token is removed from the local storage
      if (isProxyViewScopeGroup && !proxyViewId && proxyViewRight) {
        removeProxyView()
          .catch((err) => {
            dispatch(catchHandler(err));
          })
          .finally(() => {
            history.push('/transfer/proxy-view');
          });
      }
    }
  }, [
    authToken,
    isProxyViewScopeGroup,
    decodedToken?.employeeId,
    dispatch,
    history,
    initialToken,
    proxyViewId,
    proxyViewRight,
    removeProxyView,
  ]);

  return (
    <ProxyViewContext.Provider
      value={{
        showProxyViewModal,
        setShowProxyViewModal,
        showCloseProxyViewModal,
        setCloseProxyViewModal,
        proxyViewId,
        setProxyViewId,
        removeProxyView,
        proxyViewManager,
        isOnProxyViewMode,
      }}
    >
      {children}
    </ProxyViewContext.Provider>
  );
};
