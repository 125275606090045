import { type QueryClient, useQuery } from '@tanstack/react-query';
import z from 'zod';

import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { notificationQueryKeys } from './notificationQueryKeys';

const unreadCountResponseSchema = z.object({
  count: z.number(),
});

export function useUnreadCountQuery() {
  const query = useQuery({
    queryKey: notificationQueryKeys.unreadCount(),
    queryFn: async () => {
      const response = await api.get('/notifications/unread/count');
      return validateData(response, unreadCountResponseSchema, {
        errorMessagePrefix: 'unreadCountResponseSchema',
      });
    },
  });

  return {
    unreadCount: query.data?.count ?? 0,
  };
}

export function clearCachedUnreadCount(queryClient: QueryClient) {
  queryClient.setQueryData(notificationQueryKeys.unreadCount(), () => {
    return {
      count: 0,
    };
  });
}
