import { type List } from 'immutable';
import get from 'lodash/get';

import Pagination from '@peakon/records/PaginationRecord';

import { getPagesFromLinks, getTotalFromMeta } from '../../utils/getParams';

const defaultIdGetter = (data: { id?: string } = {}) => data.id;

const getActionTypes = (types = []) => ({
  loading: types.map(
    (type) =>
      `${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        type
      }_LOADING`,
  ),
  success: types.map(
    (type) =>
      `${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        type
      }_SUCCESS`,
  ),
  failure: types.map(
    (type) =>
      `${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        type
      }_FAILED`,
  ),
});

const createPagination = <TEntity, TMeta>(
  reducer: (state?: List<TEntity>, action?: unknown) => List<TEntity>,
  {
    getId = defaultIdGetter,
    startTypes,
    appendTypes,
    clearTypes = [],
    createTypes = [],
    removeTypes,
    resetTypes = [],
    defaultSkip = true,
  }: $TSFixMe = {},
) => {
  const initialState = new Pagination<TEntity, TMeta>({
    list: reducer(undefined, {}),
  });

  const startActionTypes = getActionTypes(startTypes);
  const appendActionTypes = getActionTypes(appendTypes);
  const createActionTypes = getActionTypes(createTypes);
  const removeActionTypes = getActionTypes(removeTypes);

  return (
    state = initialState,
    action: $TSFixMe,
  ): Pagination<TEntity, TMeta> => {
    const isClear = clearTypes.includes(action.type);

    if (
      resetTypes.includes(action.type) ||
      startActionTypes.loading.includes(action.type) ||
      isClear
    ) {
      return new Pagination({
        id: getId(action.data),
        items: reducer(undefined, {}),
        isLoading: !isClear,
      });
    } else if (appendActionTypes.loading.includes(action.type)) {
      return state.merge({
        id: getId(action.data),
        isLoading: true,
      });
    } else if (createActionTypes.success.includes(action.type)) {
      return state.merge({
        items: reducer(state.items, action),
        total: state.total + 1,
      });
    } else if (removeActionTypes.success.includes(action.type)) {
      return state.merge({
        items: reducer(state.items, action),
        total: state.total - 1,
      });
    } else if (
      startActionTypes.success.includes(action.type) ||
      appendActionTypes.success.includes(action.type)
    ) {
      const { links = {}, meta } = action.data;

      if (state.id && state.id !== getId(action.data)) {
        return state;
      }

      return new Pagination<TEntity, TMeta>({
        id: getId(action.data),
        isLoading: false,
        items: reducer(state.items, action),
        // @ts-expect-error TS(2339): Property 'self' does not exist on type '{}'.
        self: getPagesFromLinks(links).self,
        nextUrl: get(links, 'next'),
        total: getTotalFromMeta(meta),
        meta,
      });
    } else if (
      startActionTypes.failure.includes(action.type) ||
      appendActionTypes.failure.includes(action.type)
    ) {
      return state.set('isLoading', false);
    }

    return defaultSkip
      ? state
      : state.set('items', reducer(state.items, action));
  };
};

// eslint-disable-next-line import/no-default-export
export default createPagination;
