import React, { Fragment, useEffect, useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { InView } from 'react-intersection-observer';

import { Spinner } from '@peakon/components';

import EmptyState from './EmptyState/EmptyState';
import MessagesList from './MessagesList/MessagesList';
import { catchHandler } from '../../../../actions/NotificationActions';
import {
  type ConversationContent,
  type ConversationKind,
} from '../../../../context/ConversationsContext';
import { useMessages } from '../../../../queries/conversations/useMessages';
import { currentEmployee } from '../../../../selectors/EmployeeSelectors';
import { useAppDispatch, useAppSelector } from '../../../../utils/reduxHooks';

type Props = {
  comment: ConversationContent;
  kind: ConversationKind;
  onLoad: () => void;
  onLoadNext: (isVisible?: boolean) => void;
};

export const ConversationMessages = ({
  comment,
  kind,
  onLoad,
  onLoadNext,
}: Props) => {
  const dispatch = useAppDispatch();
  const employee = useAppSelector(currentEmployee);
  const [shouldScroll, setShouldScroll] = useState(true);

  const handleError = (error: Error) => {
    dispatch(catchHandler(error));
  };

  const {
    messages,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetched,
  } = useMessages({
    commentId: comment.id,
    onError: handleError,
  });

  const handleLoadNext = async (inView: boolean) => {
    if (!inView || isFetchingNextPage) {
      return;
    }

    await fetchNextPage();

    onLoadNext();
  };

  useEffect(() => {
    if (isFetched && shouldScroll) {
      setShouldScroll(false);
      onLoad();
    }
  }, [isFetched, onLoad, shouldScroll]);

  const sortedMessages = useMemo(() => {
    return sortBy(uniqBy(messages, 'id'), 'createdAt');
  }, [messages]);

  if (isLoading && !isFetched) {
    return <Spinner color="secondary" size="small" spaced />;
  }

  return (
    <Fragment>
      {isFetchingNextPage ? (
        <Spinner color="secondary" size="small" spaced />
      ) : (
        hasNextPage &&
        isFetched &&
        !isFetchingNextPage && (
          <InView onChange={handleLoadNext} rootMargin="100px">
            <Spinner color="secondary" size="small" spaced />
          </InView>
        )
      )}
      {!isLoading && sortedMessages.length > 0 && (
        <MessagesList currentEmployee={employee} messages={sortedMessages} />
      )}
      {!isLoading && sortedMessages.length === 0 && <EmptyState kind={kind} />}
    </Fragment>
  );
};
