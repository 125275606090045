import { useLayoutEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { getTooltipHighlightDimensions, scrollToTarget } from '../../utils';
import { type ProductTourTooltip } from '../types';

// eslint-disable-next-line import/no-default-export
export default function useResponsiveTooltipHighlightStyle(
  step: ProductTourTooltip,
) {
  const [isScrollingToTarget, setIsScrollingToTarget] = useState(true);
  const [style, setStyle] = useState<
    ReturnType<typeof getTooltipHighlightDimensions> | undefined
  >(undefined);

  const { current: calculateStyle } = useRef(
    debounce(async (_step: typeof step, _style: typeof style) => {
      const dimensions = getTooltipHighlightDimensions(_step);

      /**
       *
       * HACK: Avoid flickering tool tips
       *
       * Since the consumer (the only one) uses the `isScrollingToTarget` to show/hide
       * the current tooltip we only want to set it if the dimensions actually changed
       * otherwise the tool tip will flicker.
       *
       * We explicitly don't include the `setIsScrollingToTarget(false)` in the
       * check since that always has to happen since we use this for resizing
       * too, and there we want to always hide things :/
       *
       * This should be cleaned up, so we can get rid of the mix of scrolling,
       * styling and visibility.
       *
       */

      if (!isEqual(dimensions, _style)) {
        setIsScrollingToTarget(true);
        await scrollToTarget(_step);
        setStyle(dimensions);
      }

      setIsScrollingToTarget(false);
    }, 100),
  );

  useLayoutEffect(() => {
    const resizeListener = () => {
      setIsScrollingToTarget(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
      calculateStyle(step, style);
    };

    // Do an initial calculation
    resizeListener();

    const mutationObserver = new MutationObserver(() =>
      calculateStyle(step, style),
    );

    // We want to recalculate the tooltip highlight position (and scroll to it)
    // after any DOM change, since new content might have been loaded and moved
    // the initial position of the element
    const container =
      window.document.getElementById('app-content') ?? window.document.body;
    mutationObserver.observe(container, { subtree: true, childList: true });

    // Recalculate on resize
    window.addEventListener('resize', resizeListener);

    return () => {
      mutationObserver.disconnect();
      window.removeEventListener('resize', resizeListener);
    };
    // eslint-disable-next-line react-compiler/react-compiler
  }, [calculateStyle, step, style]);

  return { isScrollingToTarget, style };
}
