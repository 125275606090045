import React, { useState, useEffect } from 'react';

import { NavigationBasicNavigationMenuIcon as MenuIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Tooltip } from '@peakon/components';
import SkipNav from '@peakon/shared/components/SkipNav';
import { t } from '@peakon/shared/features/i18next/t';

import HelpDropdown from './HelpDropdown/HelpDropdown';
import Logo from './Logo';
import NotificationsDropdown from './NotificationsDropdown';
import ProfileDropdown from './ProfileDropdown';
import Tab from './Tab';
import { useProxyViewContext } from '../../../contexts/ProxyViewContext';
import { getLinkType } from '../getLinkType';
import useIsCollapsible from '../hooks/useIsCollapsible';
import { useNavigation } from '../hooks/useNavigation';
import {
  type ProductName,
  type RawNavigation,
} from '../hooks/useNavigation/types';
import CollapsibleSideNav from '../Side/CollapsibleSideNav';

import styles from './styles.css';

type IconHintProps = {
  children?: React.ReactNode;
  label?: string;
};

function IconHint({ children, label }: IconHintProps) {
  return (
    // @ts-expect-error Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>> | undefined'.
    <Tooltip.Core target={children} trigger="mouseenter focus">
      <Tooltip.Content>{label}</Tooltip.Content>
    </Tooltip.Core>
  );
}

type TopNavigationCommonProps = {
  employee?: {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  };
  welcomeTourItem?: React.ReactNode;
  navigation: RawNavigation;
  shouldRenderNotifications: boolean;
  shouldRenderProfile: boolean;
};

type IconsAreaProps = TopNavigationCommonProps & {
  isCollapsible: boolean;
};

const IconsArea = ({
  employee,
  isCollapsible,
  welcomeTourItem,
  navigation,
  shouldRenderNotifications,
  shouldRenderProfile,
}: IconsAreaProps) => {
  const helpLabel = t('multi_product__help');

  if (isCollapsible) {
    return (
      <div className={styles.iconsArea}>
        {shouldRenderNotifications && <NotificationsDropdown />}
        <HelpDropdown helpLabel={helpLabel} welcomeTourItem={welcomeTourItem} />
        {shouldRenderProfile && (
          <ProfileDropdown navigation={navigation} employee={employee} />
        )}
      </div>
    );
  } else {
    return (
      <ul className={styles.iconsArea}>
        {shouldRenderNotifications && (
          <li>
            <NotificationsDropdown />
          </li>
        )}
        <li>
          <IconHint label={helpLabel}>
            {/* need to wrap in span or use forwardRef https://github.com/atomiks/tippyjs-react#component-children */}
            <span>
              <HelpDropdown
                helpLabel={helpLabel}
                welcomeTourItem={welcomeTourItem}
              />
            </span>
          </IconHint>
        </li>
        {shouldRenderProfile && (
          <li>
            <ProfileDropdown navigation={navigation} employee={employee} />
          </li>
        )}
      </ul>
    );
  }
};

type TopNavigationProps = TopNavigationCommonProps & {
  isFallbackActiveProduct?: boolean;
  activeProductName: ProductName;
  isLoading?: boolean;
};

const TopNavigation = ({
  isFallbackActiveProduct,
  navigation,
  activeProductName,
  employee,
  isLoading,
  welcomeTourItem,
  shouldRenderNotifications,
  shouldRenderProfile,
}: TopNavigationProps) => {
  const activeProduct = navigation[activeProductName];
  const activeProductHomeMenu =
    activeProduct &&
    activeProduct.children &&
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    activeProduct.children[0].children &&
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    activeProduct.children[0].children[0];

  const tabs = [
    navigation.insights,
    navigation.analysis,
    navigation.improve,
    navigation.administration,
    navigation.employeeDashboard,
  ].filter(Boolean);

  return (
    <header className={styles.topNavigation}>
      <SkipNav>{t('multi_product__a11y__skip_to_content')}</SkipNav>

      <Logo
        to={
          activeProductHomeMenu &&
          activeProductHomeMenu.getPath &&
          activeProductHomeMenu.getPath()
        }
      />
      {!isLoading && (
        <nav aria-label={t('multi_product__main_navigation_landmark_label')}>
          <ul className={styles.tabs} data-test-id="navigation-top-tabs">
            {tabs.map((product) => {
              if (!product) {
                return null;
              }
              const to = product.name;
              const from = activeProductName;
              const homePage =
                product.children &&
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                product.children[0].children &&
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                product.children[0].children[0];

              const linkType = getLinkType({
                isFallbackActiveProduct: Boolean(isFallbackActiveProduct),
                to,
                from,
              });

              return (
                <Tab
                  key={product.menuLabel}
                  linkType={linkType}
                  data-test-id={product.dataTestId}
                  to={homePage?.getPath && homePage.getPath()}
                  isActive={product.isActive}
                  productName={product.name}
                  aria-current={product.isActive}
                >
                  {product.menuLabel}
                </Tab>
              );
            })}
          </ul>
        </nav>
      )}
      <IconsArea
        employee={employee}
        isCollapsible={false}
        welcomeTourItem={welcomeTourItem}
        navigation={navigation}
        shouldRenderNotifications={shouldRenderNotifications}
        shouldRenderProfile={shouldRenderProfile}
      />
    </header>
  );
};

type CollapsibleTopNavigationProps = TopNavigationCommonProps & {
  isLoading?: boolean;
  companyName?: string;
  isTest: boolean;
};

const CollapsibleTopNavigation = ({
  isLoading,
  navigation,
  companyName,
  employee,
  isTest,
  welcomeTourItem,
  shouldRenderNotifications,
  shouldRenderProfile,
}: CollapsibleTopNavigationProps) => {
  const [collapsibleSideNavIsShown, setCollapsibleSideNavIsShown] =
    useState(false);

  return (
    <div className={styles.topNavigation}>
      <SkipNav>{t('multi_product__a11y__skip_to_content')}</SkipNav>
      <div className={styles.toggler}>
        <UnstyledButton
          accessibleName={
            collapsibleSideNavIsShown
              ? t('multi_product__a11y__collapse_menu')
              : t('multi_product__a11y__open_menu')
          }
          className={styles.iconLink}
          onClick={() =>
            setCollapsibleSideNavIsShown(!collapsibleSideNavIsShown)
          }
          aria-expanded={collapsibleSideNavIsShown}
          id="collapsible-side-nav-button"
          aria-controls="collapsible-side-nav"
        >
          {/* @ts-expect-error TS(2322) FIXME: Type '{ "aria-hidden": true; alt: string; }' is no... Remove this comment to see the full error message */}
          <MenuIcon aria-hidden alt="" />
        </UnstyledButton>
      </div>
      <IconsArea
        employee={employee}
        isCollapsible={true}
        welcomeTourItem={welcomeTourItem}
        navigation={navigation}
        shouldRenderNotifications={shouldRenderNotifications}
        shouldRenderProfile={shouldRenderProfile}
      />
      <CollapsibleSideNav
        isTest={isTest}
        isLoading={isLoading}
        navigation={navigation}
        isShown={collapsibleSideNavIsShown}
        onClose={() => setCollapsibleSideNavIsShown(false)}
        companyName={companyName}
        employee={employee}
      />
    </div>
  );
};

type TopNavigationWrapperProps = {
  employee?: {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  };
  isTest: boolean;
  welcomeTourItem?: React.ReactNode;
};

export const TopNavigationWrapper = ({
  employee,
  isTest,
  welcomeTourItem,
}: TopNavigationWrapperProps) => {
  const isCollapsible = useIsCollapsible();
  const { proxyViewId } = useProxyViewContext();

  useEffect(() => {
    /*
      Add a class to the document body so we reliably can tell wether
      the multi nav is in collapsible mode or not in Css
    */
    if (isCollapsible) {
      window.document.body?.classList.add('multiNavIsCollapsible');
    } else {
      window.document.body?.classList.remove('multiNavIsCollapsible');
    }

    return () => {
      window.document.body?.classList.remove('multiNavIsCollapsible');
    };
  }, [isCollapsible]);

  useEffect(() => {
    if (proxyViewId) {
      window.document.body?.classList.add('withProxyView');
    } else {
      window.document.body?.classList.remove('withProxyView');
    }

    return () => {
      window.document.body?.classList.remove('withProxyView');
    };
  }, [proxyViewId]);

  const {
    isLoading,
    navigation,
    activeProductName,
    companyName,
    isFallbackActiveProduct,
  } = useNavigation();

  const shouldRenderNotifications =
    activeProductName !== 'employeeDashboard' && !proxyViewId;
  const shouldRenderProfile = !proxyViewId;

  if (!navigation || !activeProductName) {
    return null;
  }

  return (
    <div className={styles.root} data-test-id="navigation-top">
      {isCollapsible ? (
        <CollapsibleTopNavigation
          navigation={navigation}
          companyName={companyName}
          employee={employee}
          isTest={isTest}
          isLoading={isLoading}
          welcomeTourItem={welcomeTourItem}
          shouldRenderNotifications={shouldRenderNotifications}
          shouldRenderProfile={shouldRenderProfile}
        />
      ) : (
        <TopNavigation
          isFallbackActiveProduct={isFallbackActiveProduct}
          navigation={navigation}
          activeProductName={activeProductName}
          employee={employee}
          isLoading={isLoading}
          welcomeTourItem={welcomeTourItem}
          shouldRenderNotifications={shouldRenderNotifications}
          shouldRenderProfile={shouldRenderProfile}
        />
      )}
    </div>
  );
};
