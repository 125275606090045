import React from 'react';

import { Typography } from '@peakon/components';
import { AnonymousAvatar } from '@peakon/shared/components/avatars/AnonymousAvatar/AnonymousAvatar';
import { t } from '@peakon/shared/features/i18next/t';

import { listCommentManagers } from '../../../../actions/CommentActions';
import { type ConversationContent } from '../../../../context/ConversationsContext';
import { useRightsChecker } from '../../../../hooks/useRightsChecker';
import { useAppDispatch, useAppSelector } from '../../../../utils/reduxHooks';
import { Comment } from '../../../Comment/Core';

import styles from './styles.css';

type Props = {
  comment: ConversationContent;
  isPermalink: boolean;
};

export const ConversationComment = ({ comment, isPermalink }: Props) => {
  const commentDateVisibility = useAppSelector(
    (state) => state.company.commentDateVisibility,
  );

  const dispatch = useAppDispatch();
  const [hasCommentManagers] = useRightsChecker([
    `comment:${comment.type}:managers`,
  ]);

  return (
    <div className={styles.root}>
      <Typography.P className={styles.author}>
        {t('conversation__employee')}
      </Typography.P>
      <div className={styles.container}>
        <div className={styles.info}>
          <AnonymousAvatar size="conversation" />
        </div>
        <div className={styles.comment}>
          <Comment
            comment={comment}
            topics={comment.topics?.toJS()}
            commentDateVisibility={commentDateVisibility}
            withHighlightBanner={false}
            onLoadManagers={
              hasCommentManagers
                ? () => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
                    dispatch(
                      listCommentManagers(comment.id, {
                        singleComment: isPermalink,
                      }),
                    );
                  }
                : undefined
            }
            isLoadingManagers={
              hasCommentManagers ? comment.isLoadingManagers : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};
