import React from 'react';

import { type Message } from '../../../../../queries/conversations/types/message';
import ConversationMessage from '../../../../conversations/ConversationMessage/ConversationMessage';

import styles from './styles.css';

type Props = {
  currentEmployee?: {
    name?: string | null;
  };
  messages: Message[];
};

const MessagesList = ({ currentEmployee, messages }: Props) => {
  return (
    <ul className={styles.root}>
      {messages.map((message) => {
        return (
          <li key={message.id}>
            <ConversationMessage
              currentEmployeeName={currentEmployee?.name}
              message={message}
              animated={message.isNew}
            />
          </li>
        );
      })}
    </ul>
  );
};

// eslint-disable-next-line import/no-default-export
export default MessagesList;
