import React, { lazy, Suspense, useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';

import ErrorScreen from '@peakon/shared/components/ErrorHandling/OldErrorScreen';
import { analytics } from '@peakon/shared/features/analytics/analytics';
// eslint-disable-next-line no-restricted-imports
import { useTrackPageViews } from '@peakon/shared/features/analytics/useTrackPageViews';
import { useSprig } from '@peakon/shared/features/sprig/useSprig';
import { ProxyViewProvider } from '@peakon/shared/providers/ProxyViewProvider';
import { useCookieConsentContext } from '@peakon/shell/components/CookieConsent/CookieConsentContext';

import CheckRedirectToSubdomain from './CheckRedirectToSubdomain/CheckRedirectToSubdomain';
import * as FeatureActions from '../../actions/FeatureActions';
import { useFeature } from '../../hooks/useFeature';
import { getAnalyticsEnrichmentData } from '../../selectors/SessionSelectors';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { Routes } from '../AppRoutes/Routes';
import { StandaloneSuggestionPage } from '../StandaloneSuggestionPage/StandaloneSuggestionPage';

// eslint-disable-next-line import/order -- can probably move import freely, just being cautious
import 'bootstrap-4-grid';

const AppChrome = lazy(() => import('../AppChrome'));
const ConfirmAccount = lazy(() => import('../ConfirmAccount/ConfirmAccount'));
const LoginRoutes = lazy(() =>
  import('../login/LoginRoutes').then((m) => ({
    default: m.LoginRoutes,
  })),
);
const NoSurveys = lazy(() => import('../NoSurveys/NoSurveys'));
const SamlAssert = lazy(() => import('../SamlAssert/SamlAssert'));
const StaticRoutes = lazy(() =>
  import('../staticPages/StaticRoutes').then((m) => ({
    default: m.StaticRoutes,
  })),
);
const UnsubscribeRoute = lazy(() =>
  import('../unsubscribe/UnsubscribeRoute').then((m) => ({
    default: m.UnsubscribeRoute,
  })),
);
const StandaloneConversationPage = lazy(
  () => import('../StandaloneConversationPage/StandaloneConversationPage'),
);
const TransferRoutes = lazy(() =>
  import('../transfer/routes/TransferRoutes').then((m) => ({
    default: m.TransferRoutes,
  })),
);
const OAuthSubdomainChecker = lazy(
  () => import('../OAuthSubdomainChecker/OAuthSubdomainChecker'),
);
const SurveySsoFlow = lazy(() => import('../SurveySsoFlow/SurveySsoFlow'));

const AppRoutes = () => (
  <ProxyViewProvider>
    <AppChrome>
      <Routes />
    </AppChrome>
  </ProxyViewProvider>
);

/**
 * This is a temporary solution to handle the cookie consent prompt feature flip
 * until it is launched fully
 */
function useCookieConsentFeatureFlip() {
  const { isCookieConsentFeatureEnabled, setIsCookieConsentFeatureEnabled } =
    useCookieConsentContext();
  const _isCookieConsentFeatureEnabled = useFeature('cookieConsentPrompt');
  // we need to wrap this in a useEffect to prevent calling setState during App render
  // or we will get warnings in the console
  useEffect(() => {
    if (_isCookieConsentFeatureEnabled !== isCookieConsentFeatureEnabled) {
      setIsCookieConsentFeatureEnabled(_isCookieConsentFeatureEnabled);
    }
  }, [
    isCookieConsentFeatureEnabled,
    setIsCookieConsentFeatureEnabled,
    _isCookieConsentFeatureEnabled,
  ]);
}

const App = () => {
  useTrackPageViews();
  useCookieConsentFeatureFlip(); // FIXME: remove after launch
  const dispatch = useAppDispatch();

  const colorMode = useAppSelector((state) => state.account?.colorMode);

  const analyticsEnrichmentData = useAppSelector(getAnalyticsEnrichmentData);

  // Note: For some reason `useAddon()` will make the app blow up when the user logs out...
  const hasSprigAddOn = useAppSelector((state) =>
    state.company.addOns.includes('sprig'),
  );

  useSprig({ hasSprigAddOn, userId: analyticsEnrichmentData?.distinct_id });

  const isAuthenticated = useAppSelector(
    (state) => state.session.isAuthenticated,
  );

  useEffect(() => {
    if (analyticsEnrichmentData) {
      analytics.identifyUser(analyticsEnrichmentData);
    }
  }, [analyticsEnrichmentData]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(FeatureActions.featuresRead());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (colorMode) {
      window.document
        .querySelector('html')
        ?.setAttribute('data-theme', colorMode);
    } else {
      window.document.querySelector('html')?.removeAttribute('data-theme');
    }
  }, [colorMode]);

  return (
    <Suspense fallback={null}>
      <CheckRedirectToSubdomain>
        <Switch>
          <Route
            path="/:provider(slack|microsoft-teams|bamboohr)/complete"
            component={OAuthSubdomainChecker}
          />
          <Route
            path="/signup/confirm/:token"
            exact
            component={ConfirmAccount}
          />
          <Route path="/login" component={LoginRoutes} />
          <Route path="/unsubscribe/:token" component={UnsubscribeRoute} />
          <Route path="/surveys/no-surveys" component={NoSurveys} />
          <Route path="/saml/:companyId/assert" component={SamlAssert} />
          <Route
            path="/conversations/reply/:token"
            component={StandaloneConversationPage}
          />
          <Route
            path="/team_suggestions/:token"
            component={StandaloneSuggestionPage}
          />
          <Route
            path={['/what-is-peakon', '/kiosk-export']}
            component={StaticRoutes}
          />
          <Route path="/transfer" component={TransferRoutes} />
          <Route path="/employee-voice" component={SurveySsoFlow} exact />
          <Route
            path={[
              '/account',
              '/courses',
              '/improve',
              '/admin',
              '/actions',
              '/dashboard',
              '/metrics',
              '/slack',
              '/microsoft-teams',
              '/bamboohr',
              '/workday',
              '/oauth',
            ]}
          >
            <AppRoutes />
          </Route>
          {/* This needs to be separated from the above declaration so we can have 'exact'
              and render 404 without the shell */}
          <Route path="/" exact>
            <AppRoutes />
          </Route>
          <Route component={ErrorScreen} />
        </Switch>
      </CheckRedirectToSubdomain>
    </Suspense>
  );
};

// eslint-disable-next-line import/no-default-export
export default App;
