import { List, OrderedMap } from 'immutable';

import jsonapiparser from '@peakon/jsonapiparser';
import {
  Employee,
  ManagingSegments,
  LegacyPagination,
  SegmentManager,
} from '@peakon/records';

import stringComparator from '../utils/stringComparator';

const managingSegments = (state = new ManagingSegments(), action: $TSFixMe) => {
  switch (action.type) {
    case 'MANAGING_SEGMENTS_LIST_SUCCESS': {
      const { data: response } = action;

      // @ts-expect-error return from jsonapiparser is unknown
      const { data } = jsonapiparser(response);

      return state.set(
        'segments',
        List(data.map(SegmentManager.createFromApi)),
      );
    }

    case 'MANAGING_SEGMENTS_ADD_MANAGER_SUCCESS': {
      const { data: response } = action;

      // @ts-expect-error return from jsonapiparser is unknown
      const { data } = jsonapiparser(response);

      return state.set(
        'segments',
        // @ts-expect-error Argument of type 'Iterable<number, SegmentManager>... Remove this comment to see the full error message (ts2345)
        state.segments
          .push(SegmentManager.createFromApi(data))
          .sort((a, b) =>
            stringComparator(
              // @ts-expect-error 'a.segment' is possibly 'undefined'
              a.segment.name,
              // @ts-expect-error 'b.segment' is possibly 'undefined'
              b.segment.name,
            ),
          )
          .sort((a, b) =>
            stringComparator(
              // @ts-expect-error 'a.segment' is possibly 'undefined'
              a.segment.attribute.name,
              // @ts-expect-error 'b.segment' is possibly 'undefined'
              b.segment.attribute.name,
            ),
          ),
      );
    }

    case 'MANAGING_SEGMENTS_REMOVE_MANAGER_SUCCESS': {
      const { id } = action.data;

      return state.set(
        'segments',
        // @ts-expect-error TS(2339): Property 'segments' does not exist on type 'Managi... Remove this comment to see the full error message
        state.segments.filter(({ segment }) => segment.id !== id),
      );
    }

    case 'MANAGING_SEGMENTS_EMPLOYEE_LIST_SUCCESS': {
      const {
        data,
        links,
        meta: {
          page: { total },
        },
      } = action.data;

      return state.set(
        'employees',
        new LegacyPagination({
          list: OrderedMap(
            // @ts-expect-error TS(7006): Parameter 'employee' implicitly has an 'any' type.
            data.map((employee) => [
              employee.id,
              Employee.createFromApi(employee),
            ]),
          ),

          nextUrl: links && links.next,
          total,
        }),
      );
    }

    case 'MANAGING_SEGMENTS_EMPLOYEE_LIST_LOADING':
    case 'MANAGING_SEGMENTS_EMPLOYEE_LOAD_MORE_LOADING':
      return state.updateIn(['employees'], (employees) =>
        employees.set('isLoading', true),
      );

    case 'MANAGING_SEGMENTS_EMPLOYEE_LOAD_MORE_SUCCESS': {
      const { data: response } = action;
      // @ts-expect-error return from jsonapiparser is unknown
      const { links, data } = jsonapiparser(response);

      return state.updateIn(['employees'], (employees) =>
        employees.merge({
          isLoading: false,
          // @ts-expect-error Type 'Iterable<string, unknown>' is missing the following properties from type 'OrderedMap<string, unknown>'
          list: employees.list.concat(
            // @ts-expect-error TS(7006): Parameter 'employee' implicitly has an 'any' type.
            data.map((employee) => [
              employee.id,
              Employee.createFromApi(employee),
            ]),
          ),

          nextUrl: links && links.next,
        }),
      );
    }

    case 'MANAGING_SEGMENTS_RESET_EMPLOYEES':
      return state.set(
        'employees',
        new LegacyPagination({
          isLoading: true,
        }),
      );

    case 'MANAGING_SEGMENTS_STOP':
      return new ManagingSegments();

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default managingSegments;
