import React, { forwardRef } from 'react';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Placeholder } from '@peakon/components';
import ContextLabel from '@peakon/shared/components/context/ContextLabel';
import Markdown from '@peakon/shared/components/Markdown';
import formatYearless from '@peakon/shared/utils/formatYearless';

import { Icon } from './Icon';
import {
  type NotificationItemBaseAttributesData,
  type NotificationItemBaseAttributesType,
  type NotificationItemContext,
} from './types';

import styles from './styles.css';

type Props = {
  isLoading?: boolean;
  isSelected?: boolean;
  notification: {
    context?: NotificationItemContext;
    createdAt: string;
    data?: NotificationItemBaseAttributesData;
    deleted?: boolean;
    id: string;
    status: string;
    text: string;
    type: NotificationItemBaseAttributesType;
    url: string;
  };
};

export const Notification = forwardRef<HTMLAnchorElement, Props>(
  ({ isLoading, isSelected: _, notification, ...props }, ref) => {
    if (isLoading) {
      return (
        <div className={classnames(styles.root, styles.loading)}>
          <div className={styles.icon}>
            <Placeholder style={{ height: 22, width: 22 }} />
          </div>
          <div className={styles.content}>
            <Placeholder
              style={{ height: 15, marginBottom: 5, width: '90%' }}
            />
            <Placeholder
              style={{ height: 15, marginBottom: 10, width: '60%' }}
            />
            <div className={styles.footer}>
              <Placeholder style={{ height: 15, opacity: 0.5, width: 120 }} />
              <Placeholder style={{ height: 15, opacity: 0.5, width: 45 }} />
            </div>
          </div>
        </div>
      );
    }

    const { pathname, search } = new URL(notification.url);

    return (
      <li role="none">
        <Link
          ref={ref}
          {...props}
          to={`${pathname}${search}`}
          className={classnames(styles.root, {
            [styles.unread]: notification.status === 'unread',
            [styles.sensitive]: notification.type === 'sensitiveComment',
            [styles.deleted]: notification.deleted,
          })}
        >
          <div className={styles.icon}>
            <Icon type={notification.type} data={notification.data} />
          </div>
          <div className={styles.content}>
            <div className={styles.text}>
              <Markdown>{notification.text}</Markdown>
            </div>
            {notification.context && (
              <div className={styles.footer}>
                <ContextLabel
                  context={notification.context}
                  modifier="notification"
                  type="card"
                />
                {notification.createdAt && (
                  <time className={styles.time}>
                    {formatYearless(notification.createdAt, 'lls')}
                  </time>
                )}
              </div>
            )}
          </div>
        </Link>
      </li>
    );
  },
);

Notification.displayName = 'Notification';
