import { List } from 'immutable';
import { REHYDRATE } from 'redux-persist';

import { Filter, FilterOption } from '@peakon/records';
import { getDefaultFilters } from '@peakon/records/defaultFilters';

const filtersState = (
  state = List<Filter>(),
  // @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
  action,
): List<Filter> => {
  switch (action.type) {
    case REHYDRATE: {
      const { filters } = action.payload ?? {};

      return Array.isArray(filters) || List.isList(filters)
        ? List(filters.map(Filter.rehydrate))
        : List();
    }
    case 'ATTRIBUTE_READ_SUCCESS': {
      const { data, hasEphemeralEmployees } = action.data;
      const enabledDefaultFilters = getDefaultFilters();
      const currentActiveFilters = state
        .filter((f) => f.enabled && (f.id !== 'type' || hasEphemeralEmployees))
        .map((f) => f.id);

      const defaultFilters = enabledDefaultFilters.filter(
        (filter) => filter.id !== 'type' || hasEphemeralEmployees,
      );

      // first load
      if (state.size === 0) {
        // @ts-expect-error TS(2740): Type 'Iterable<number, unknown>' is missing the fo... Remove this comment to see the full error message
        return List(data.map(Filter.createFromAttribute)).concat(
          defaultFilters,
        );
      }

      // data contains the attributes read from the api
      // enabledDefaultFilters contains filters on properties that are not attributes
      // as engagement enabled or email bounced
      return [...data, ...defaultFilters].reduce((acc, filter) => {
        const nextFilter =
          typeof filter.group !== 'undefined'
            ? filter // enabledDefaultFilters are already of type FilterRecord
            : Filter.createFromAttribute(filter);

        // when there is a filter that we had enabled, merge it
        if (currentActiveFilters.includes(nextFilter.id)) {
          const prevFilter = state.find(
            (filterItem) => filterItem.id === nextFilter.id,
          );

          return acc.push(nextFilter.refresh(prevFilter));
        }

        return acc.push(nextFilter);
      }, List());
    }
    case 'FILTER_TOGGLED': {
      const { id, enabled } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) => (enabled ? filter.set('enabled', true) : filter.clear()),
      );
    }
    case 'FILTER_CLEAR_ALL': {
      return state.map((filter) => filter.clear());
    }
    case 'FILTER_CLEAR': {
      const { id } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) => filter.clear(),
      );
    }
    case 'FILTER_CONDITION_TYPE_CHANGED': {
      const { id, type } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) => filter.set('conditionType', type),
      );
    }
    case 'FILTER_RANGE_TYPE_CHANGED': {
      const { id, type } = action.data;

      if (type === null) {
        return state.update(
          state.findIndex((filter) => filter.id === id),
          (filter) => filter.set('range', null),
        );
      }

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) => filter.set('range', type),
      );
    }
    case 'FILTER_RANGE_CHANGED': {
      const { id, range = { to: null, from: null } } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) =>
          filter.merge({
            from: range.from,
            to: range.to,
          }),
      );
    }
    case 'FILTER_EMPLOYEE_CHANGED': {
      const { id, employeeIds } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) =>
          // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
          filter.update('selected', () => List(employeeIds)),
      );
    }
    case 'FILTER_TREE_CHANGED': {
      const { id, treeNodes } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) => filter.selectOptions(treeNodes),
      );
    }
    case 'FILTER_RADIO_CHANGED':
    case 'FILTER_TIMEZONE_CHANGED':
    case 'FILTER_LOCALE_CHANGED': {
      const { id, value } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) => filter.set('value', value),
      );
    }
    case 'FILTER_OPTIONS_CHANGED': {
      const { id, options } = action.data;

      return state.update(
        state.findIndex((filter) => filter.id === id),
        (filter) => filter.selectOptions(options),
      );
    }
    case 'FILTER_GET_ATTRIBUTE_TREE_NODES_SUCCESS':
    case 'FILTER_GET_ATTRIBUTE_OPTIONS_SUCCESS': {
      const { id, data } = action.data;

      const index = state.findIndex((filter) => filter.id === id);

      if (index === -1) {
        return state;
      }

      const options = List(
        [FilterOption.getNotSet()].concat(
          data.map(
            (
              // @ts-expect-error TS(7006): Parameter 'option' implicitly has an 'any' type.
              option,
            ) =>
              new FilterOption({
                id: option.id,
                label: option.attributes.name,
                labelTranslated: option.attributes.nameTranslated,
              }),
          ),
        ),
      );

      return state.update(index, (filter) => filter.set('options', options));
    }
    case 'FILTER_GET_MORE_ATTRIBUTE_TREE_NODES_SUCCESS':
    case 'FILTER_GET_MORE_ATTRIBUTE_OPTIONS_SUCCESS': {
      const { id, data } = action.data;

      const index = state.findIndex((filter) => filter.id === id);

      if (index === -1) {
        return state;
      }

      const newOptions = List(
        data.map(
          (
            // @ts-expect-error TS(7006): Parameter 'option' implicitly has an 'any' type.
            option,
          ) =>
            new FilterOption({
              id: option.id,
              label: option.attributes.name,
              labelTranslated: option.attributes.nameTranslated,
            }),
        ),
      );

      return state.updateIn([index, 'options'], (options) =>
        options.concat(newOptions),
      );
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default filtersState;
