import React, { useState } from 'react';

import { AccessVisibilityCommentIcon as CommentManagersIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { t } from '@peakon/shared/features/i18next/t';

import { ManagerSlideIn } from './ManagerSlideIn/ManagerSlideIn';

import styles from './styles.css';

type Props = {
  isLoading?: boolean;
  managers?: Array<{
    id?: string;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    avatar?: string | null;
    email?: string | null;
    identifier?: string;
  }>;
  onLoad: () => void;
};

export const CommentManagers = ({ isLoading, managers, onLoad }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onLoad();
  };

  return (
    <div className={styles.root}>
      <UnstyledButton
        accessibleName={t('comment__managers')}
        onClick={handleOpen}
      >
        <CommentManagersIcon />
      </UnstyledButton>
      <ManagerSlideIn
        isOpen={isOpen}
        isLoading={Boolean(isLoading)}
        onDismiss={() => setOpen(false)}
        managers={managers}
      />
    </div>
  );
};
