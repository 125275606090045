import {
  type InfiniteData,
  type QueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { z } from 'zod';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import { useAppDispatch } from './../../../../../../../app-manager/src/utils/reduxHooks';
import { notificationQueryKeys } from './notificationQueryKeys';
import { catchHandler } from '../../../../../../../app-manager/src/actions/NotificationActions';
import { validateData } from '../../../../../utils/validateData/validateData';
import { notificationItemSchema } from '../Notification/types';

type NotificationItem = z.infer<typeof notificationItemSchema>;
type RelationshipsKeys = keyof NotificationItem['relationships'];
type NotificationAttributes = NotificationItem['attributes'];

const URL_PARAMS = {
  include: 'context,context.attribute',
  per_page: 10,
};

type GetParams = {
  nextPageLink?: string;
};

const getNotificationsResponseSchema = z.object({
  data: z.array(notificationItemSchema),
  links: z.object({
    next: z.string().optional(),
  }),
});

const getNotifications = async ({ nextPageLink }: GetParams) => {
  const URL: [string, typeof URL_PARAMS | null] = nextPageLink
    ? [nextPageLink, null]
    : ['/notifications', URL_PARAMS];
  const response = await api.get(...URL);

  return validateData(jsonapiparser(response), getNotificationsResponseSchema, {
    errorMessagePrefix: 'getNotificationsResponseSchema',
  });
};

export type GetNotificationsResponse = Awaited<
  ReturnType<typeof getNotifications>
>;

export const useGetNotificationsInfiniteQuery = ({ enabled = false }) => {
  const appDispatch = useAppDispatch();

  const { data, ...rest } = useInfiniteQuery({
    queryKey: notificationQueryKeys.notifications(),
    queryFn: ({ pageParam }) => getNotifications({ nextPageLink: pageParam }),
    getNextPageParam: (previousData) => previousData?.links?.next,
    onError: (error) => appDispatch(catchHandler(error)),
    enabled,
  });

  return {
    data,
    notifications:
      data?.pages?.flatMap((page) => page?.data).map(normalize) ?? [],
    ...rest,
  };
};

function normalize(item: NotificationItem | null) {
  if (!item) {
    return null;
  }

  type NormalizedRelationships = {
    [key in RelationshipsKeys]?:
      | ({ id: string } & NotificationAttributes & NormalizedRelationships)
      | null;
  };

  const relationships: NormalizedRelationships = {};

  if (item.relationships) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const keys = Object.keys(item.relationships) as RelationshipsKeys[];
    for (const key of keys) {
      relationships[key] = normalize(item.relationships[key]);
    }
  }

  return {
    id: item.id,
    ...item.attributes,
    ...relationships,
  };
}

export const markCachedNotificationsAsRead = (queryClient: QueryClient) => {
  queryClient.setQueryData<InfiniteData<GetNotificationsResponse>>(
    notificationQueryKeys.notifications(),
    (oldNotifications) => {
      if (!oldNotifications) {
        return;
      }

      const readNotificationPages = oldNotifications.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((notification) => ({
            ...notification,
            attributes: {
              ...notification.attributes,
              readAt: new Date().toISOString(),
              status: 'read',
            },
          })),
        };
      });

      return { ...oldNotifications, pages: readNotificationPages };
    },
  );
};
