import React from 'react';

import {
  ScoresEmoji00Icon,
  ScoresEmoji01Icon,
  ScoresEmoji02Icon,
  ScoresEmoji03Icon,
  ScoresEmoji04Icon,
  ScoresEmoji05Icon,
  ScoresEmoji06Icon,
  ScoresEmoji07Icon,
  ScoresEmoji08Icon,
  ScoresEmoji09Icon,
  ScoresEmoji10Icon,
} from '@peakon/bedrock/icons/graphic';

const scores = {
  0: ScoresEmoji00Icon,
  1: ScoresEmoji01Icon,
  2: ScoresEmoji02Icon,
  3: ScoresEmoji03Icon,
  4: ScoresEmoji04Icon,
  5: ScoresEmoji05Icon,
  6: ScoresEmoji06Icon,
  7: ScoresEmoji07Icon,
  8: ScoresEmoji08Icon,
  9: ScoresEmoji09Icon,
  10: ScoresEmoji10Icon,
} as const;

export type Props = {
  className?: string;
  score: keyof typeof scores;
};

export const ScoreComponent = ({ className, score }: Props) => {
  const IconComponent = scores[score];

  return (
    <IconComponent
      className={className}
      // @ts-expect-error - Property 'title' does not exist on type 'IntrinsicAttributes & SVGProps<SVGSVGElement>'.
      title={score}
      data-score={score}
    />
  );
};
