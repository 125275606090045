import React, { useMemo } from 'react';

import classnames from 'classnames';

import { Select } from '@peakon/bedrock/react/form';
import { type TimezoneValue } from '@peakon/shared/data/timezones';
import { t } from '@peakon/shared/features/i18next/t';

import getTimezones from './utils/getTimezones';

import styles from './styles.css';

type Props = {
  disabled?: boolean;
  id?: string;
  isAbsolutePosition?: boolean;
  isFilter?: boolean;
  label?: string;
  onSelect: (value: TimezoneValue | null) => void;
  placeholder?: string;
  description?: string;
  selected: string | null | undefined;
};

function TimezoneSelector({
  isFilter,
  selected = null,
  onSelect,
  disabled,
  id,
  label,
  placeholder,
  isAbsolutePosition,
  description,
}: Props) {
  const timezones = useMemo(() => {
    return getTimezones(isFilter);
  }, [isFilter]);

  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  label = label || t('account__timezone');
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  placeholder = isFilter
    ? placeholder || t('filters__select__placeholder')
    : placeholder || t('account__timezone__placeholder');

  return (
    <div
      data-test-id="timezone-selector"
      className={classnames({
        [styles.fixed]: isAbsolutePosition,
      })}
    >
      <Select
        id={id}
        label={label}
        value={selected ?? ''}
        disabled={disabled}
        // @ts-expect-error typeof value is TimezoneValue
        onChange={(e) => onSelect(e.target.value || null)}
        description={description}
        hideLabel={isFilter}
      >
        <Select.Option value="" disabled={isFilter}>
          {placeholder}
        </Select.Option>
        {timezones.map(({ value, label: timezoneLabel, group }) => (
          <Select.Option key={value} value={value}>
            {`${timezoneLabel}${group ? ` (${group})` : ''}`}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default TimezoneSelector;
