import { useEffect, useRef, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useRealtimeChannel from '@peakon/shared/hooks/useRealtimeChannel';
import { useShellContext } from '@peakon/shell';
import { proxyViewSnapshot } from '@peakon/shell/hooks/useTokenFromStorage';

import { notificationQueryKeys } from './queries/notificationQueryKeys';
import {
  markCachedNotificationsAsRead,
  useGetNotificationsInfiniteQuery,
} from './queries/useGetNotificationsInfiniteQuery';
import {
  clearCachedUnreadCount,
  useUnreadCountQuery,
} from './queries/useUnreadCountQuery';
import api from '../../../../utils/api';

export function useNotifications({ employeeId }: { employeeId: string }) {
  const connectionRef = useRef(false);
  const queryClient = useQueryClient();
  const { session } = useShellContext();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { unreadCount } = useUnreadCountQuery();
  const { markAllAsRead } = useMarkAsReadMutation();

  const rights =
    session && 'rights' in session.data.attributes
      ? session.data.attributes.rights
      : [];

  const hasNotificationReadRight = rights.includes('notification:read');

  const {
    notifications,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading,
    isFetching,
  } = useGetNotificationsInfiniteQuery({
    enabled: isMenuOpen && hasNotificationReadRight,
  });

  const channel = useRealtimeChannel(`notifications/${employeeId}`, {
    autoConnect: false,
    client: api,
  });

  const onToggleMenu = async (isOpen: boolean) => {
    setIsMenuOpen(isOpen);

    // Mark all as read when the menu closes
    if (!isOpen) {
      await markAllAsRead();
    }
  };

  useEffect(() => {
    if (
      channel &&
      employeeId &&
      !connectionRef.current &&
      !proxyViewSnapshot()
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      channel.join();
      channel.on('new', () =>
        queryClient.invalidateQueries(notificationQueryKeys.all),
      );
      connectionRef.current = true;
    }
  }, [channel, employeeId, queryClient]);

  return {
    unread: unreadCount,
    isEmpty: notifications.length === 0,
    loadMore: fetchNextPage,
    hasMore: Boolean(hasNextPage),
    isFetching,
    isInitialLoading,
    isLoadingMore: isFetchingNextPage,
    onToggleMenu,
    notifications,
  };
}

function useMarkAsReadMutation() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => api.post('/notifications/read'),
    onMutate: async () => {
      await queryClient.cancelQueries(notificationQueryKeys.all);
      markCachedNotificationsAsRead(queryClient);
      clearCachedUnreadCount(queryClient);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(notificationQueryKeys.all);
    },
  });

  return {
    markAllAsRead: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
}
