import { useEffect, useRef, useState } from 'react';

import RealtimeChannel from '../utils/realtime/RealtimeChannel';

// eslint-disable-next-line import/no-default-export
export default function useRealtimeChannel(
  channel: string | undefined,
  { autoConnect = false, client }: { autoConnect: boolean; client: $TSFixMe },
) {
  const ref = useRef<RealtimeChannel | null>(null);
  const [_, setSocket] = useState<typeof channel | null>(null);

  useEffect(() => {
    if (!channel) {
      ref.current?.leave();
      return;
    }
    ref.current = new RealtimeChannel(channel, {
      getToken: () => client.auth.restoreToken(),
    });
    if (autoConnect) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
      ref.current.join();
    }
    // hack to force a re-render, we only care when we
    // have changed channel, so let's use the channel as key
    setSocket(channel);

    return () => {
      ref.current?.leave();
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoConnect, channel, setSocket]);

  return ref.current;
}
