import identity from 'lodash/identity';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import { v4 as uuidv4 } from 'uuid';

import jsonapiparser from '@peakon/jsonapiparser';
import { type AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import api from '@peakon/shared/utils/api';
import hasRight from '@peakon/shared/utils/hasRight';

import { currentContext } from '../selectors/ContextSelectors';
import { getDatasetParams } from '../selectors/SessionSelectors';
import { type Dispatch, type GetState } from '../types/redux';
import { asyncDispatch } from '../utils';

export const CATEGORIES_FIELDS = [
  'parentCategory',
  'group',
  'standard',
  'legacyDriver',
  'legacySubdriver',
  'nameTranslated',
  'descriptionTranslated',
  'theory',
];

export const QUESTION_FIELDS = ['context', 'text', 'category', 'value'];

export const COMMENT_FIELDS = [
  'acknowledgement',
  'acknowledgementCounts',
  'answeredAt',
  'closesAt',
  'comment',
  'commentType',
  'driver',
  'locale',
  'messageCount',
  'question',
  'responseType',
  'score',
  'question',
  'topicsInfo',
  'semanticTags',
  'sensitive',
  'sensitiveMatches',
  'commentTranslation',
];

type ListCommentsForDriversParams = {
  drivers: string[];
  perPage?: number;
};

export const listCommentsForDrivers = ({
  drivers,
  perPage,
}: ListCommentsForDriversParams) => {
  return listComments({
    sort: 'createdAt',
    order: 'desc',
    filter: drivers && { ['question.driver']: `${drivers.join(',')}$in` }, // eslint-disable-line no-useless-computed-key
    perPage,
  });
};

type ListCommentsForSubdriversParams = {
  subdrivers: string[];
  perPage?: number;
};

export const listCommentsForSubdrivers = ({
  subdrivers,
  perPage,
}: ListCommentsForSubdriversParams) => {
  return listComments({
    sort: 'createdAt',
    order: 'desc',
    filter: subdrivers && {
      ['question.subdriver']: `${subdrivers.join(',')}$in`, // eslint-disable-line no-useless-computed-key
    },
    perPage,
  });
};

export const listComments =
  ({
    type = 'driver',
    filter = {},
    perPage = 30,
    sort,
    order,
    includeSubcategories,
  }: {
    type?: string;
    filter: Record<string, unknown>;
    perPage?: number;
    sort: string;
    order: string;
    includeSubcategories?: boolean;
  }) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const dashboardContext = currentContext(state);
    const hasCommentAccess = hasRight(dashboardContext.rights?.toArray(), [
      'comment:read',
      'comment:driver:read',
    ]);

    const datasetParams = getDatasetParams(state);

    const hasCommentRank = state.company.addOns.includes('comment_rank');

    const fields = hasCommentRank
      ? [...COMMENT_FIELDS, 'commentRelevance']
      : COMMENT_FIELDS;

    const params = datasetParams({
      per_page: perPage,
      filter,
      sort,
      order,
      includeSubcategories,
      fields: {
        comments: fields.join(','),
        categories: CATEGORIES_FIELDS.join(','),
        questions: QUESTION_FIELDS.join(','),
        comment_translations: 'sourceLocale,targetLocale,text',
      },

      include: [
        'acknowledgement',
        'question',
        'question.value',
        'question.category',
        'question.category.parentCategory',
        'semanticTags',
        'commentTranslation',
      ].join(','),
    });

    const url = type
      ? `/comments/contexts/${dashboardContext.id}/types/${type}`
      : `/comments/contexts/${dashboardContext.id}`;

    // FIXME: investigate, and remove the failing dispatched action
    // https://github.com/peakon/dashboard/commit/06b0862f36eb19687f2935493adec88793a01b69
    return hasCommentAccess
      ? asyncDispatch({
          dispatch,
          resource: 'COMMENT_LIST',
          action: api.get(url, pickBy(params, identity)).then(jsonapiparser),
        })
      : dispatch({
          type: 'COMMENT_LIST_FAIL',
          data: {
            error: new Error('Missing access rights'),
          },
        });
  };

export const getCommentsForSemanticTopic =
  (
    id: string,
    {
      segmentId,
      ...queryParams
    }: Record<string, unknown> & { segmentId?: string } = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const { company } = state;
    const context = currentContext(state);
    const datasetParams = getDatasetParams(state);

    const hasCommentRank = company.addOns.includes('comment_rank');

    const fields = hasCommentRank
      ? [...COMMENT_FIELDS, 'commentRelevance']
      : COMMENT_FIELDS;

    const params = datasetParams(
      merge(
        {
          fields: {
            comments: [...fields, 'semanticTags', 'semanticTagFeedbacks'].join(
              ',',
            ),
            question: ['text', 'driver', 'subdriver', 'value'].join(','),
            semanticTags: ['name'].join(','),
            semanticTagFeedbacks: ['*'].join(','),
            comment_translations: 'sourceLocale,targetLocale,text',
          },
          filter: {
            'semanticTag.id': id,
          },
          include: [
            'semanticTags',
            'semanticTagFeedbacks',
            'acknowledgement',
            'question',
            'question.value',
            'question.category',
            'commentTranslation',
          ].join(','),
        },
        queryParams,
      ),
      { segmentId },
    );

    return asyncDispatch({
      dispatch,
      resource: 'TOPIC_SEMANTIC_COMMENT_LIST',
      data: { id, params: queryParams },
      action: api
        .get(`/comments/contexts/${context.id}`, params)
        .then(jsonapiparser),
    });
  };

export const getCommentsForTopic =
  (
    id: string,
    {
      segmentId,
      ...queryParams
    }: Record<string, unknown> & { segmentId?: string } = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const { company } = state;
    const context = currentContext(state);
    const datasetParams = getDatasetParams(state);

    const hasCommentRank = company.addOns.includes('comment_rank');

    const fields = hasCommentRank
      ? [...COMMENT_FIELDS, 'commentRelevance']
      : COMMENT_FIELDS;

    const params = datasetParams(
      merge(
        {
          fields: {
            comments: fields.join(','),
            question: ['text', 'driver', 'subdriver', 'value'].join(','),
            comment_translations: 'sourceLocale,targetLocale,text',
          },

          filter: {
            'topic.id': id,
          },

          include: [
            'acknowledgement',
            'question',
            'semanticTags',
            'question.value',
            'question.category',
            'commentTranslation',
          ].join(','),
        },
        queryParams,
      ),

      { segmentId },
    );

    return asyncDispatch({
      dispatch,
      resource: 'TOPIC_COMMENT_LIST',
      data: { id, params: queryParams },
      action: api
        .get(`/comments/contexts/${context.id}`, params)
        .then(jsonapiparser),
    });
  };

export const listCommentManagers =
  (id: string, { singleComment }: { singleComment?: boolean } = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    let url = `/comments/${id}/managers`;

    if (!singleComment) {
      const context = currentContext(state);
      url += `/contexts/${context.id}`;
    }

    return asyncDispatch({
      dispatch,
      resource: 'COMMENT_MANAGERS_LIST',
      data: {
        id,
      },

      action: api.get(url).then(jsonapiparser),
    });
  };

export const deleteComment = (id: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'COMMENT_DELETE',
    data: {
      id,
    },

    action: api.delete(`/comments/${id}`),
  });

export const acknowledgeComment =
  (id: string, type: AcknowledgementType) => (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'COMMENT_ACKNOWLEDGE',
      data: {
        id,
        type,
      },

      action: api
        .post(
          `/comments/${id}/acknowledge`,
          {
            fields: {
              comments: 'acknowledgement,acknowledgementCounts',
            },

            include: 'acknowledgement',
          },

          {
            data: {
              type: 'answer_acknowledgements',
              attributes: {
                type,
              },
            },
          },
        )
        .then(jsonapiparser),
    });

export const sendSemanticTopicsFeedback =
  ({
    commentId,
    isRelevant,
    isBiased,
    isBiasedComment,
    topicId,
  }: {
    topicId: string;
    commentId: string;
    isRelevant?: boolean;
    isBiased?: boolean;
    isBiasedComment?: string;
  }) =>
  (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'COMMENT_SEND_SEMANTIC_TOPICS_FEEDBACK',
      data: {
        commentId,
        isRelevant,
        isBiased,
        isBiasedComment,
      },
      action: api
        .post(`/semantic_tags/${topicId}/feedback`, null, {
          data: {
            attributes: {
              isRelevant,
              isBiased,
              isBiasedComment: !isBiased ? undefined : isBiasedComment,
              answerId: commentId,
            },
            type: 'semantic_tag_feedbacks',
          },
        })
        .then(jsonapiparser),
    });

export const sendFeedback =
  ({
    commentId,
    searchTerm,
    isRelevant,
    isBiased,
    isBiasedComment,
  }: {
    commentId: string;
    searchTerm: string;
    isRelevant?: boolean;
    isBiased?: boolean;
    isBiasedComment?: string;
  }) =>
  (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'COMMENT_SEND_SEMANTIC_SEARCH_FEEDBACK',
      data: {
        commentId,
        searchTerm,
        isRelevant,
        isBiased,
        isBiasedComment,
      },
      action: api
        .post(
          `/comments/${commentId}/semanticSearchFeedback`,
          {
            fields: {
              searchTerm,
              isRelevant,
              isBiased,
              isBiasedComment,
            },
          },
          {
            data: {
              attributes: {
                searchTerm,
                isRelevant,
                isBiased,
                isBiasedComment: !isBiased ? undefined : isBiasedComment,
              },
              type: 'comment_semantic_search_feedbacks',
            },
          },
        )
        .then(jsonapiparser),
    });

export const onTranslate =
  (id: string, { type }: { type?: 'overview' } = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { comments } = getState();

    const list = type === 'overview' ? comments.overview.items : comments.list;

    const comment = list.find((c) => c.id === id);

    if (comment.translation) {
      return dispatch({
        type: 'COMMENT_TRANSLATE_SUCCESS',
        data: { id, translation: comment.translation },
      });
    }

    return asyncDispatch({
      dispatch,
      resource: 'COMMENT_TRANSLATE',
      data: { id },
      action: api.post(`/comments/${id}/translate`),
    });
  };

export const onRevertTranslate = (id: string) => ({
  type: 'COMMENT_REVERT_TRANSLATE',
  data: { id },
});

export const markAsSensitive = (id: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'COMMENT_MARK_SENSITIVE',
    data: {
      id,
    },

    action: api.post(`/comments/${id}/sensitive`),
  });

export const markNotSensitive = (id: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'COMMENT_MARK_NOT_SENSITIVE',
    data: {
      id,
    },

    action: api.delete(`/comments/${id}/sensitive`),
  });

export const getMoreCommentsForTopic =
  (id: string, url: string) => (dispatch: Dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'TOPIC_COMMENT_APPEND',
      data: { id, req: uuidv4() },
      action: api.get(url).then(jsonapiparser),
    });
  };

export const clearAll = () => ({
  type: 'COMMENT_CLEAR_ALL',
});

export const exportComments =
  (
    id: string,
    {
      segmentId,
      ...queryParams
    }: Record<string, unknown> & { segmentId?: string } = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const { company } = state;
    const context = currentContext(state);
    const datasetParams = getDatasetParams(state);

    const hasCommentRank = company.addOns.includes('comment_rank');

    const fields = hasCommentRank
      ? [...COMMENT_FIELDS, 'commentRelevance']
      : COMMENT_FIELDS;

    const params = datasetParams(
      merge(
        {
          fields: {
            comments: fields.join(','),
            question: ['text', 'driver', 'subdriver', 'value'].join(','),
            comment_translations: 'sourceLocale,targetLocale,text',
          },

          filter: {
            'topic.id': id,
          },

          include: [
            'acknowledgement',
            'question',
            'question.value',
            'commentTranslation',
          ].join(','),
        },
        queryParams,
      ),

      { segmentId },
    );

    return asyncDispatch({
      dispatch,
      resource: 'TOPIC_COMMENTS_EXPORT',
      data: { id, params: queryParams },
      action: api.post(`/comments/contexts/${context.id}/export`, params),
    });
  };

export const get = (id: string) => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  const { company } = state;
  const datasetParams = getDatasetParams(state);

  const hasCommentRank = company.addOns.includes('comment_rank');

  const fields = hasCommentRank
    ? [...COMMENT_FIELDS, 'commentRelevance']
    : COMMENT_FIELDS;

  const params = datasetParams({
    fields: {
      comments: fields.join(','),
      categories: CATEGORIES_FIELDS.join(','),
      question: QUESTION_FIELDS.join(','),
      semanticTags: ['name'].join(','),
      comment_translations: 'sourceLocale,targetLocale,text',
    },

    include: [
      'acknowledgement',
      'question',
      'question.value',
      'question.category',
      'question.category.parentCategory',
      'semanticTags',
      'commentTranslation',
    ].join(','),
  });

  return asyncDispatch({
    dispatch,
    resource: 'COMMENT_GET',
    data: { id, params },
    action: api.get(`/comments/${id}`, params).then(jsonapiparser),
  });
};

export const listByContext =
  (
    params: {
      fields: Record<string, string>;
      include: string;
      sort: string | null;
      order: 'asc' | 'desc';
      [key: `filter[${string}]`]: string;
    },
    isNewSemanticSearchRoute?: boolean,
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const contextId = currentContext(getState()).id;

    const url = isNewSemanticSearchRoute
      ? `/comments/contexts/${contextId}/semanticSearch`
      : `/comments/contexts/${contextId}`;

    return asyncDispatch({
      dispatch,
      resource: 'COMMENTS_LIST_BY_CONTEXT',
      action: api.get(url, params).then(jsonapiparser),
    });
  };

export const loadMoreByContext = (url: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'COMMENTS_LOAD_MORE_BY_CONTEXT',
    action: api.get(url).then(jsonapiparser),
  });

export const markAsRead = () => (dispatch: Dispatch, getState: GetState) => {
  const contextId = currentContext(getState()).id;

  return asyncDispatch({
    dispatch,
    resource: 'COMMENTS_MARK_AS_READ',
    action: api.post(`/comments/contexts/${contextId}/mark_as_read`),
  });
};

export const toggleActive = (comment?: { id: string } | null) => ({
  type: 'COMMENT_SET_ACTIVE',

  data: {
    id: comment ? comment.id : null,
  },
});

export const conversationMessageAdded = (id: string) => ({
  type: 'COMMENTS_CONVERSATION_MESSAGE_ADDED',

  data: {
    id,
  },
});
