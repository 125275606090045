import React, { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { z } from 'zod';

import { GodModeMain } from '@peakon/shared/components/Dev/GodModeMain';
import ErrorBoundary from '@peakon/shared/components/ErrorHandling/ShellErrorBoundary';
import { COLLAPSED_MAX_WIDTH } from '@peakon/shared/constants/breakpoints';
import { usePreviousTruthy } from '@peakon/shared/hooks/usePreviousTruthy';
import {
  useSearchParams,
  useValidatedSearchParams,
} from '@peakon/shared/hooks/useSearchParams';
import { type ContextResponse } from '@peakon/shared/schemas/api/contexts';
import api from '@peakon/shared/utils/api';
import categoryGroupService from '@peakon/shared/utils/categoryGroups';
import { errorReporter } from '@peakon/shared/utils/errorReporter';
import featureFactory from '@peakon/shared/utils/featureFactory';
import { getRightsMap } from '@peakon/shared/utils/rights';

import { fetchContext, getContextById } from './utils/fetchContext';
import { isConsultant as getIsConsultant } from './utils/isConsultant';
import { type AuthOptions, useSessionQuery } from './utils/queries';
import {
  type CompanyForm,
  type ShellContextType,
  getShellContext,
} from '../../context/ShellContext';
import {
  type NullableSession,
  type Session,
  isSharedDashboardSession,
} from '../../types/Session';
import { authWithToken } from '../../utils/auth';
import { updateErrorSession } from '../../utils/errorHelper';
import redirectTo from '../../utils/redirectTo';

function screenExpandCheckerFn(range: number) {
  return () => window.innerWidth > range;
}

type Props = {
  authenticate?: (authOptions?: AuthOptions) => Promise<NullableSession>;
  authOptions?: AuthOptions;
  statusPageHost?: string;
  version?: string;
  loginUrl?: string;
  onAuthenticated?: (session: Session) => void;
  screenExpandChecker?: () => boolean;
  twoFactorUrl?: string;
  app?: 'shared-dashboard';
  withAuthentication?: boolean;
  onError?: (error: Error) => void;
  children:
    | React.ReactNode
    | ((context: ShellContextType<NullableSession>) => React.ReactNode);
};

type State = {
  isSidebarExpanded: boolean;
  hasOutage?: boolean;
  hasNewVersion?: boolean;
};

const getRightsAndGroupFromContext = (
  context: ContextResponse | null | undefined,
  session: Session,
) => {
  const rights = getRightsMap({
    context,
    session: session.data,
  });

  const mainCategoryGroup = categoryGroupService.getMainCategoryGroup(context);

  const mainCategoryGroupWithDrivers =
    categoryGroupService.getMainCategoryGroupWithDrivers(context);

  return {
    rights,
    mainCategoryGroup,
    mainCategoryGroupWithDrivers,
  };
};

const Shell = ({
  children,
  authenticate: authenticateProps = authWithToken,
  loginUrl = '/login',
  twoFactorUrl = '/login/twofactor',
  screenExpandChecker = screenExpandCheckerFn(COLLAPSED_MAX_WIDTH),
  statusPageHost = 'https://status.peakon.com',
  withAuthentication = true,
  authOptions,
  onAuthenticated: onAuthenticatedProps,
  version,
  app,
  onError,
}: Props) => {
  const ShellContext = getShellContext();

  const [state, setState] = useState<State>({
    isSidebarExpanded: screenExpandChecker(),
  });

  const onAuthenticated = (session: NullableSession) => {
    if (!session) {
      redirectTo(loginUrl);
      return;
    } else if (
      !isSharedDashboardSession(session) &&
      session?.data?.attributes?.twofactorStatus === 'required'
    ) {
      redirectTo(twoFactorUrl);
      return;
    }

    updateErrorSession(errorReporter, session);
    onAuthenticatedProps?.(session);
  };

  const {
    isFetching: isLoadingSession,
    data: session,
    refetch: authenticate,
  } = useSessionQuery({
    authenticate: authenticateProps,
    authOptions,
    onAuthenticated,
    withAuthentication,
    errorReporter,
    app,
  });

  const {
    contexts,
    isLoading: isLoadingContextQueries,
    rights,
    mainCategoryGroup,
    mainCategoryGroupWithDrivers,
    currentContext,
    onCompanyUpdated,
  } = useContextState({
    session,
    onError,
    isLoadingSession,
  });

  // handle resize
  useEffect(() => {
    const onResize = () => {
      setState((prevState) => ({
        ...prevState,
        isSidebarExpanded: screenExpandChecker(),
      }));
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [screenExpandChecker]);

  const sessionAttributes = session?.data.attributes;

  const context: ShellContextType<NullableSession> = {
    ...state,
    features: featureFactory(session?.data?.attributes?.features ?? [])
      .filter((feature) => feature.active)
      .map((feature) => feature.key),
    isConsultant: getIsConsultant({ session }),
    isPartner:
      sessionAttributes &&
      'scopeGroup' in sessionAttributes &&
      sessionAttributes?.scopeGroup === 'partnerConsultant',
    rights,
    mainCategoryGroup,
    mainCategoryGroupWithDrivers,
    context: currentContext ?? null,
    contexts,
    isLoading: isLoadingSession || isLoadingContextQueries,
    session,
    isAuthenticated: Boolean(session),
    onToggleExpand: () => {
      setState((prevState) => ({
        ...prevState,
        isSidebarExpanded: !prevState.isSidebarExpanded,
      }));
    },
    onToggleOutage: () => {
      setState((prevState) => ({
        ...prevState,
        hasOutage: !prevState.hasOutage,
      }));
    },
    onNewVersion: () => {
      setState((prevState) => ({
        ...prevState,
        hasNewVersion: true,
      }));
    },
    onCompanyUpdated,

    // props
    authenticate,
    statusPageHost,
    version,
  };

  return (
    <ErrorBoundary errorReporter={errorReporter}>
      <ShellContext.Provider value={context}>
        <React.Fragment>
          <GodModeMain session={session} />
          {typeof children === 'function' ? children(context) : children}
        </React.Fragment>
      </ShellContext.Provider>
    </ErrorBoundary>
  );
};

// eslint-disable-next-line import/no-default-export
export default Shell;

const useContextState = ({
  session,
  onError,
  isLoadingSession,
}: {
  session: NullableSession;
  isLoadingSession: boolean;
  onError?: (error: Error) => void;
}) => {
  const [{ contextId: contextIdFromUrl }] = useValidatedSearchParams({
    validationSchema: z.object({
      contextId: z.string().nullable(),
    }),
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { contextByIdQuery, contextsQuery, invalidate } =
    useContextSwitcherQuery({
      session,
      contextId: contextIdFromUrl,
    });

  useEffect(() => {
    if (contextByIdQuery.error instanceof Error) {
      onError?.(contextByIdQuery.error);
    }
  }, [contextByIdQuery.error, onError]);

  const isLoadingContexts =
    contextsQuery.isFetching || contextByIdQuery.isFetching;

  const prevContextId = usePreviousTruthy(contextIdFromUrl);

  const contexts = contextsQuery.data?.contexts ?? [];

  const getCurrentContext = () => {
    // errored out (probably missing permissions) so we redirect to default
    if (contextByIdQuery.isError) {
      return contextsQuery.data?.defaultContext;
    }

    // undefined: we need to wait for the loading state when changing context
    if (isLoadingContexts) {
      return undefined;
    }

    // contextId was removed from URL, so we use the previous context
    // this is for cases when we forget to pass it in the URL
    if (!contextIdFromUrl && prevContextId) {
      return contexts.find((ctx) => ctx.id === prevContextId);
    }

    return (
      contexts.find((ctx) => ctx.id === contextIdFromUrl) ??
      // default: nothing in url or previous (eg when logging in)
      contextsQuery.data?.defaultContext
    );
  };

  const currentContext = getCurrentContext();

  const rightsState = session
    ? getRightsAndGroupFromContext(currentContext, session)
    : {
        rights: {},
        mainCategoryGroup: undefined,
        mainCategoryGroupWithDrivers: undefined,
      };

  const onCompanyUpdated = async (_form?: CompanyForm) => {
    await invalidate();
  };

  const contextId = currentContext?.id;

  useEffect(() => {
    if (isLoadingSession || isLoadingContexts || !contextId) {
      return;
    }

    if (contextId !== contextIdFromUrl) {
      searchParams.set('contextId', contextId);
      setSearchParams(searchParams, {
        // it's the default but want to be explicit we want to replace
        method: 'replace',
      });
    }
  }, [
    contextId,
    contextIdFromUrl,
    isLoadingContexts,
    isLoadingSession,
    searchParams,
    setSearchParams,
  ]);

  return {
    ...rightsState,
    isLoading: isLoadingContexts,
    contexts,
    currentContext,
    onCompanyUpdated,
  };
};

export const contextsQueryKey = (session: NullableSession) => [
  'context-switcher',
  session,
];

const useContextSwitcherQuery = ({
  session,
  contextId,
}: {
  session: NullableSession;
  contextId: string | null;
}) => {
  const client = useQueryClient();

  const contextsQuery = useQuery({
    queryKey: contextsQueryKey(session),
    enabled: Boolean(session),
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    queryFn: () =>
      fetchContext({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        session: session!,
        client: api,
      }),
  });

  const contextByIdQuery = useQuery({
    queryKey: ['context-switcher', contextId, session],
    enabled: Boolean(
      contextId &&
        // make sure this only runs if it's not in the contexts
        contextsQuery.data?.defaultContext &&
        !contextsQuery.data?.contexts.find((ctx) => ctx.id === contextId),
    ),
    retry: false,
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fetchedContext = await getContextById(contextId!);
      if (!fetchedContext) {
        return;
      }

      if (session) {
        const newContextId = fetchedContext.id;

        // upsert the new context
        client.setQueryData<(typeof contextsQuery)['data']>(
          contextsQueryKey(session),
          (oldData) => {
            if (!oldData) {
              errorReporter.error(
                'contextsQuery has to run before contextByIdQuery',
              );
              return;
            }

            // Employees will always have access to the company context to access basic information
            // that's why we do this check here when they are trying to access the Shell
            if (
              fetchedContext.attributes.level === 'company' &&
              isEqual(fetchedContext.attributes.rights, ['employee:list'])
            ) {
              return { ...oldData };
            }

            let contexts: ContextResponse[] = [];
            if (!oldData.contexts.find((c) => c.id === newContextId)) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              contexts = [...oldData!.contexts!, fetchedContext];
            } else {
              contexts = oldData.contexts.map((ctx) =>
                ctx.id === newContextId ? fetchedContext : ctx,
              );
            }
            return {
              contexts,
              defaultContext: oldData.defaultContext,
            };
          },
        );
      }

      return fetchedContext;
    },
  });

  const invalidate = () => client.invalidateQueries(['context-switcher']);

  return { contextsQuery, contextByIdQuery, invalidate };
};
