import { useEffect, useRef } from 'react';

export function usePreviousTruthy<TValue>(
  value: TValue,
  { defaultValue }: { defaultValue?: TValue } = {},
) {
  const ref = useRef<TValue | undefined>();

  useEffect(() => {
    if (value) {
      ref.current = value;
    }
  }, [value]);

  // eslint-disable-next-line react-compiler/react-compiler
  if (typeof ref.current === 'undefined') {
    return defaultValue;
  }
  // eslint-disable-next-line react-compiler/react-compiler
  return ref.current;
}
