import React from 'react';

import { LinkButton } from '@peakon/bedrock/react/button';
import { Stack } from '@peakon/bedrock/react/layout';
import { BodyText, Heading1 } from '@peakon/bedrock/react/typography';
import ErrorImage from '@peakon/shared/assets/images/temporary/misc/Unavailable_error-page@2x.png';
import { t, type TranslatedContent } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export type Props = {
  illustration: JSX.Element;
  heading: TranslatedContent;
  description: TranslatedContent;
  children?: React.ReactNode;
  withLinkToHelpCenter: boolean;
};

export const FullPageError = ({
  illustration,
  heading,
  description,
  children,
  withLinkToHelpCenter,
}: Props) => {
  return (
    <div className={styles.fullScreenContainer}>
      <div className={styles.errorContainer}>
        <Stack alignInline="center" spacing={16}>
          <div className={styles.illustration}>{illustration}</div>
          <Heading1 level={1} textAlign="center">
            {heading}
          </Heading1>
          {description && <BodyText textAlign="center">{description}</BodyText>}
          <Stack alignInline="center" spacing={8}>
            {children && <div>{children}</div>}
            {withLinkToHelpCenter && (
              <LinkButton
                variant="tertiary"
                href="https:doc.workday.com/en-us/peakon.html"
              >
                {t('generic_error_page__visit_help_center')}
              </LinkButton>
            )}
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export const GenericFullPageError = () => {
  return (
    <FullPageError
      illustration={<img src={ErrorImage} alt="" />}
      heading={t('generic_error_page__title')}
      description={t('generic_error_page__description')}
      withLinkToHelpCenter
    >
      <LinkButton variant="primary" href="/">
        {t('generic_error_page__go_to_dashboard')}
      </LinkButton>
    </FullPageError>
  );
};
