import { Record, List, Map, fromJS } from 'immutable';
import moment from 'moment';
import { z } from 'zod';

import { type TranslatedString } from '@peakon/shared/features/i18next/t';
import { type GeneralAttributeResponse } from '@peakon/shared/schemas/api/attributes';
import {
  type AttributeStandard,
  type AttributeType,
} from '@peakon/shared/types/Attribute';
import { notEmpty } from '@peakon/shared/utils/typescript/notEmpty';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import {
  NOT_IN,
  IN,
  GTE,
  LTE,
  LT,
  type Condition,
} from './constants/conditions';
import {
  type FilterData,
  type FilterGroup,
  type FilterSelectOption,
  type RangeFromTo,
} from './types/Filter';
import { validateTestingSchema } from './utils';

const filterSortSchema = z.object({
  direction: z.enum(['ASC', 'DESC']).optional(),
  query: z.string().optional(),
});
const testingFilterSortSchema = filterSortSchema.extend({
  sortBy: z.enum(['name']),
});
type FilterSortSchema = z.infer<typeof filterSortSchema>;

export class FilterSort
  extends Record({
    sortBy: 'name',
    direction: 'ASC',
    query: '',
  })
  implements FilterSortSchema
{
  // FIXME: rename prop 'sort' to something else to avoid clashing with immutable
  // @ts-expect-error Property 'sortBy' in type 'FilterSort' is not assignable to the same property in base...
  sortBy!: string;
  direction!: FilterSortSchema['direction'];
  query!: string;

  constructor(props: unknown = {}) {
    validateRecord(props, filterSortSchema, {
      errorMessagePrefix: 'FilterSort',
    });
    validateTestingSchema(props, testingFilterSortSchema, {
      errorMessagePrefix: 'FilterSort',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  getSortBy() {
    switch (this.sortBy) {
      case 'engagement':
        return 'features.engagement';
      default:
        return this.sortBy;
    }
  }
}

const filterOptionSchema = z.object({
  id: z.string().optional(),
});
const testingFilterOptionSchema = filterOptionSchema.extend({
  exclusive: z.any(),
  default: z.any(),
  label: z.any(),
  from: z.any(),
  to: z.any(),
  filter: z.any(),
  labelTranslated: z.any(),
});
type FilterOptionSchema = z.infer<typeof filterOptionSchema>;

export class FilterOption
  extends Record({
    id: undefined,
    label: undefined,
    labelTranslated: undefined,
    from: undefined,
    to: undefined,
    exclusive: false,
    filter: undefined,
    default: false,
  })
  implements FilterOptionSchema
{
  id!: FilterOptionSchema['id'];
  label?: string;
  labelTranslated?: TranslatedString;
  from?: RangeFromTo;
  to?: RangeFromTo;
  exclusive!: boolean;
  // FIXME: rename prop 'filter' to something else to avoid clashing with immutable
  // @ts-expect-error TS2416 Property 'filter' in type 'FilterOption' is not assignable to the same property in base type
  filter?: Map<string, string>;
  default!: boolean;

  constructor(props: unknown = {}) {
    validateRecord(props, filterOptionSchema, {
      errorMessagePrefix: 'FilterOption',
    });
    validateTestingSchema(props, testingFilterOptionSchema, {
      errorMessagePrefix: 'FilterOption',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  isNotSet() {
    return this.id === 'not_set';
  }

  static getNotSet() {
    return new FilterOption({
      id: 'not_set',
      exclusive: true,
    });
  }
}

const filterSchema = z.object({
  id: z.string(),
});
const testingFilterSchema = filterSchema.extend({
  default: z.any(),
  enabled: z.any(),
  labelTranslated: z.any(),
  from: z.any(),
  label: z.any(),
  conditionType: z.any(),
  type: z.any(),
  to: z.any(),
  selected: z.any(),
  range: z.any(),
  standard: z.any(),
  options: z.any(),
  group: z.any(),
  exclusive: z.any(),
});
type FilterSchema = z.infer<typeof filterSchema>;

class Filter
  extends Record({
    id: undefined,
    label: undefined,
    labelTranslated: undefined,
    group: undefined,
    standard: undefined,

    // option attributes
    selected: List(),
    options: List(),

    // range attributes (number/date)
    from: undefined,
    to: undefined,
    range: undefined,

    value: undefined,

    // Filter properties
    enabled: false,
    conditionType: IN,

    type: undefined,
    default: false,
  })
  implements FilterSchema
{
  id!: FilterSchema['id'];
  label?: string;
  labelTranslated?: TranslatedString;
  group?: FilterGroup;
  standard?: AttributeStandard;
  selected!: List<FilterOption | string>;
  options!: List<FilterOption>;
  from?: Date;
  to?: Date;
  range?: string;
  value?: string;
  enabled!: boolean;
  conditionType!: Condition;
  type?: AttributeType | 'radio' | 'timezone' | 'locale';
  default!: boolean;

  constructor(props: unknown = {}) {
    validateRecord(props, filterSchema, {
      errorMessagePrefix: 'Filter',
    });
    validateTestingSchema(props, testingFilterSchema, {
      errorMessagePrefix: 'Filter',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get testId() {
    return this.standard || this.label?.toLowerCase().replace(/s/g, '-');
  }

  clear() {
    return this.merge({
      selected: List(),
      range: undefined,
      from: undefined,
      to: undefined,
      value: undefined,
      enabled: false,
      conditionType: IN,
    });
  }

  selectOptions(options: FilterSelectOption[]) {
    return this.set(
      'selected',
      List(
        options.map(
          (option) =>
            new FilterOption({
              id: option.value,
              label: option.label,
              labelTranslated: option.labelTranslated,
            }),
        ),
      ),
    );
  }

  isOptionRange() {
    return typeof this.range !== 'undefined' && this.range !== null;
  }

  getRange() {
    if (this.isOptionRange()) {
      const selectedRange = this.options.find(
        (range) => range?.id === this.range,
      );

      if (selectedRange) {
        return selectedRange;
      }
    }

    return new FilterOption({ from: this.from, to: this.to });
  }

  getQueryKey() {
    switch (this.id) {
      case 'employee_role':
        return 'manager';
      case 'email_bounced':
        if (
          this.value === 'employees__filtering__advanced__email_complaint__true'
        ) {
          return 'account.complainedAt';
        }

        return 'account.bouncedAt';
      case 'email':
        return 'account.email';
      case 'engagement':
        return 'features';
      case 'timezone':
        return 'account.timezone';
      case 'locale':
        return 'account.locale';
      case 'createdAt':
        return 'createdAt';
      case 'employmentStatus':
        return 'employmentStatus';
      case 'type':
        return 'type';
      case 'visibility':
        return 'visibility';
      default:
        return `attribute_${this.id}`;
    }
  }

  getOptionValue() {
    const values = this.selected.map((selected) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { id } = selected as FilterOption;

      if (id === 'not_set') {
        return 'null';
      }

      return id;
    });

    return `"${values.join('","')}"$${this.conditionType}`.replace(
      /"null"/,
      'null',
    );
  }

  getTreeValue() {
    const values = this.selected.map((selected) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { id } = selected as FilterOption;

      if (id === 'not_set') {
        return 'null';
      }

      return id;
    });

    return `"${values.join('","')}"$${this.conditionType}`.replace(
      /"null"/,
      'null',
    );
  }

  getEmployeeValue() {
    const values = this.selected.map((s) => (s === 'not_set' ? 'null' : s));

    return `${values.join(',')}$${this.conditionType}`;
  }

  getDatetimeValue() {
    if (this.range && this.range === 'not_set') {
      return this.getNotSetValue();
    }

    const value = [];
    const { from, to } = this.getRange();

    if (from && to && [IN, NOT_IN].includes(this.conditionType)) {
      const fromDate = moment(from).utcOffset(0, true).startOf('day');
      const toDate = moment(to).utcOffset(0, true).endOf('day');

      if (this.conditionType === IN) {
        return [`${fromDate.toISOString()},${toDate.toISOString()}$between`];
      } else {
        return [`${fromDate.toISOString()},${toDate.toISOString()}$notBetween`];
      }
    }

    if (from) {
      let conditionType;

      if (this.conditionType === IN) {
        conditionType = GTE;
      } else if (this.isOptionRange()) {
        conditionType = LT;
      } else {
        conditionType = LTE;
      }

      const fromDate =
        conditionType === LTE
          ? moment.utc(from).endOf('day')
          : moment.utc(from).startOf('day');

      value.push(`${fromDate.toISOString()}$${conditionType}`);
    }

    if (to) {
      let conditionType;

      if (this.conditionType !== IN) {
        conditionType = GTE;
      } else if (this.isOptionRange()) {
        conditionType = LT;
      } else {
        conditionType = LTE;
      }

      const toDate =
        conditionType === LTE
          ? moment.utc(to).endOf('day')
          : moment.utc(to).startOf('day');

      value.push(`${toDate.toISOString()}$${conditionType}`);
    }

    return value;
  }

  /**
   *
   * @param from a number or a string in YYYY-MM-DD format
   * @param to a number or a string in YYYY-MM-DD format
   * @returns
   */
  getCloseRangeValue(from: string | number, to: string | number) {
    const operator = this.conditionType === IN ? '$between' : '$notBetween';

    return [`${from},${to}${operator}`];
  }

  getDateValue() {
    if (this.range && this.range === 'not_set') {
      return this.getNotSetValue();
    }

    const value: string[] = [];
    const { from, to, filter } = this.getRange();
    let conditionType;

    if (filter) {
      if (this.standard === 'separation_date') {
        return this.getSeparationDateValue(
          from === undefined ? null : from,
          to === undefined ? null : to,
        );
      }

      if (from && to) {
        const fromDate = filter.get('$gte');
        const toDate = filter.get('$lte');

        return this.getCloseRangeValue(fromDate, toDate);
      }

      return filter.reduce((acc, curr, key) => {
        if (this.conditionType === IN) {
          conditionType = key;
        } else if (key === `$${GTE}`) {
          conditionType = `$${LTE}`;
        } else {
          conditionType = `$${GTE}`;
        }

        return (acc || []).concat(`${curr}${conditionType}`);
      }, value);
    }

    if (from && to) {
      return this.getCloseRangeValue(
        moment.utc(from).format('YYYY-MM-DD'),
        moment.utc(to).format('YYYY-MM-DD'),
      );
    }

    if (from) {
      let selectedConditionType;

      if (this.conditionType === IN) {
        selectedConditionType = GTE;
      } else if (this.isOptionRange()) {
        selectedConditionType = LT;
      } else {
        selectedConditionType = LTE;
      }

      value.push(
        `${moment.utc(from).format('YYYY-MM-DD')}$${selectedConditionType}`,
      );
    }

    if (to) {
      let selectedConditionType;

      if (this.conditionType !== IN) {
        selectedConditionType = GTE;
      } else if (this.isOptionRange()) {
        selectedConditionType = LT;
      } else {
        selectedConditionType = LTE;
      }

      value.push(
        `${moment.utc(to).format('YYYY-MM-DD')}$${selectedConditionType}`,
      );
    }

    return value;
  }

  getSeparationDateValue(from: RangeFromTo, to: RangeFromTo) {
    const start = moment
      .utc()
      .add(from !== null ? Math.abs(Number(from)) : 0, 'month')
      .format('YYYY-MM-DD');

    const end = moment
      .utc()
      .add(to !== null ? Math.abs(Number(to)) : 0, 'month')
      .format('YYYY-MM-DD');

    if (from === null) {
      if (this.conditionType === IN) {
        return [`${end}$gte`];
      } else {
        return [`${end}$lte`];
      }
    } else if (to === null && this.conditionType === NOT_IN) {
      return [`${end},${start}$notBetween`];
    }

    const operator = this.conditionType === IN ? '$between' : '$notBetween';

    // need to reverse the order since separation date is treated as minus numbers
    return [`${end},${start}${operator}`];
  }

  getNumberValue() {
    if (this.range && this.range === 'not_set') {
      return this.getNotSetValue();
    }

    const value: string[] = [];
    let conditionType;
    const { from, to, filter } = this.getRange();
    const isClosedRange =
      notEmpty(from) && notEmpty(to) && from !== '' && to !== '';

    if (isClosedRange) {
      return this.getCloseRangeValue(from, to);
    }

    if (filter) {
      return filter.reduce((acc, curr, key) => {
        if (this.conditionType === IN) {
          conditionType = key;
        } else if (key === `$${GTE}`) {
          conditionType = `$${LTE}`;
        } else {
          conditionType = `$${GTE}`;
        }

        return (acc || []).concat(`${curr}${conditionType}`);
      }, value);
    }

    if (from) {
      if (this.conditionType === IN) {
        conditionType = GTE;
      } else if (this.isOptionRange()) {
        conditionType = LT;
      } else {
        conditionType = LTE;
      }

      value.push(`${from}$${conditionType}`);
    }

    if (to) {
      if (this.conditionType !== IN) {
        conditionType = GTE;
      } else if (this.isOptionRange()) {
        conditionType = LT;
      } else {
        conditionType = LTE;
      }

      value.push(`${to}$${conditionType}`);
    }

    return value;
  }

  getRadioValue() {
    switch (this.id) {
      case 'visibility':
      case 'employee_role': {
        return this.value === `${this.label}__true`;
      }
      case 'engagement': {
        const value = [];

        value.push(
          `${this.id}$${
            this.value === `${this.label}__true` ? 'contains' : 'notContains'
          }`,
        );

        return value;
      }
      case 'email':
        return `null$${this.value === `${this.label}__true` ? 'ne' : 'eq'}`;

      case 'email_bounced': {
        if (
          this.value === 'employees__filtering__advanced__email_complaint__true'
        ) {
          return 'null$ne';
        }

        return `null$${this.value === `${this.label}__true` ? 'ne' : 'eq'}`;
      }
    }
  }

  getTimezoneValue() {
    return this.value === 'not_set' ? this.getNotSetValue() : this.value;
  }

  getLocaleValue() {
    return this.value === 'not_set' ? this.getNotSetValue() : this.value;
  }

  getNotSetValue() {
    return `null${this.conditionType === NOT_IN ? '$ne' : ''}`;
  }

  isValid() {
    if (!this.enabled) {
      return false;
    }

    switch (this.type) {
      case 'date':
      case 'number':
        return this.from || this.to || this.range;
      case 'tree':
      case 'employee':
      case 'option':
        return this.selected && this.selected.size > 0;
      case 'radio':
        return typeof this.value !== 'undefined';
      case 'timezone':
      case 'locale':
        return typeof this.value === 'string';
      default:
        return true;
    }
  }

  toQueryParam() {
    if (!this.isValid()) {
      return null;
    }
    let value = null;

    switch (this.type) {
      case 'option': {
        value = this.getOptionValue();
        break;
      }
      case 'tree': {
        value = this.getTreeValue();
        break;
      }
      case 'employee': {
        value = this.getEmployeeValue();
        break;
      }
      case 'date': {
        value =
          this.id === 'createdAt'
            ? this.getDatetimeValue()
            : this.getDateValue();
        break;
      }
      case 'number': {
        value = this.getNumberValue();
        break;
      }
      case 'radio': {
        value = this.getRadioValue();
        break;
      }
      case 'timezone': {
        value = this.getTimezoneValue();
        break;
      }
      case 'locale': {
        value = this.getLocaleValue();
        break;
      }
    }

    if (this.id === 'email_bounced') {
      // if active, then add null$eq for both
      if (
        this.value === 'employees__filtering__advanced__email_bounced__false'
      ) {
        return {
          [this.getQueryKey()]: value,
          'account.complainedAt': value,
        };
      }

      // if bounced, then add null$eq for complainedAt since complainedAt
      // has higher precedence than bounceAt i.e. employee with both should
      // only show with complainedAt filter applied
      if (
        this.value === 'employees__filtering__advanced__email_bounced__true'
      ) {
        return {
          [this.getQueryKey()]: value,
          'account.complainedAt': 'null$eq',
        };
      }

      return {
        [this.getQueryKey()]: value,
      };
    }

    return {
      [this.getQueryKey()]: value,
    };
  }

  static rehydrate(data: FilterData) {
    let { options, selected, from, to, type, ...other } = data;

    if (from && type === 'date') {
      from = new Date(from);
    }

    if (to && type === 'date') {
      to = new Date(to);
    }

    return new Filter(
      fromJS({
        ...other,
        type,
        from,
        to,
        selected:
          type === 'employee'
            ? List(selected)
            : List(selected.map((s) => new FilterOption(fromJS(s)))),
        options: List(
          options.map((option) => new FilterOption(fromJS(option))),
        ),
      }),
    );
  }

  refresh(prevFilter: Filter) {
    return this.merge({
      selected: prevFilter.selected,
      from: prevFilter.from,
      to: prevFilter.to,
      range: prevFilter.range,
      enabled: prevFilter.enabled,
      value: prevFilter.value,
      conditionType: prevFilter.conditionType,
    });
  }

  static createFromAttribute(data: GeneralAttributeResponse) {
    const {
      id,
      attributes,
      relationships: { options = [], ranges = [] } = {},
    } = data;
    let filterOptions: FilterOption[] = [];

    switch (attributes.type) {
      case 'option': {
        filterOptions = options
          .map(
            (option) =>
              new FilterOption({
                id: option.id,
                label: option.attributes.name,
                labelTranslated: option.attributes.nameTranslated,
              }),
          )
          .concat([FilterOption.getNotSet()]);
        break;
      }
      case 'date':
      case 'number': {
        filterOptions = ranges
          .map((range) => {
            return new FilterOption({
              id: range.id,
              label: range.attributes.name,
              from: range.attributes.from,
              to: range.attributes.to,
              filter: Map(range.attributes.filter),
            });
          })
          .concat([FilterOption.getNotSet()]);
        break;
      }
    }

    return new Filter({
      id,
      label: attributes.name,
      labelTranslated: attributes.nameTranslated,
      standard: attributes.standard,
      group: 'attribute',
      options: List(filterOptions),
      type: attributes.type,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default Filter;
