import React from 'react';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { NotFoundScreen } from '@peakon/shared/components/NotFoundScreen/NotFoundScreen';

import { ConversationSlideIn } from '../../components/comments/ConversationSlideIn/ConversationSlideIn';
import { withLazy } from '../../components/LazyLoaded';
import { RestrictedRoute } from '../../components/RestrictedRoute';
import RedirectToAvailableRoute from '../../components/RestrictedRoute/RedirectToAvailableRoute';
import ConversationsProvider from '../../providers/ConversationsProvider';
import ProductTourProvider from '../../providers/ProductTourProvider';

const CommentsRoutes = withLazy(() =>
  import('../insight/routes/comments/CommentsRoutes').then((m) => ({
    default: m.CommentsRoutes,
  })),
);
const Driver = withLazy(() => import('../Driver'));
const Drivers = withLazy(() => import('../Drivers'));
const EmployeeExperienceRoutes = withLazy(() =>
  import('../analysis/routes/employeeExperience/EmployeeExperienceRoutes').then(
    (m) => ({ default: m.EmployeeExperienceRoutes }),
  ),
);
const ExternalMetricsRoute = withLazy(() =>
  import('../analysis/routes/ExternalMetricsRoute/ExternalMetricsRoute').then(
    (m) => ({
      default: m.ExternalMetricsRoute,
    }),
  ),
);
const NpsSummaryRoute = withLazy(() =>
  import('../insight/routes/NpsSummaryRoute/NpsSummaryRoute').then((m) => ({
    default: m.NpsSummaryRoute,
  })),
);
const Overview = withLazy(() => import('../GroupOverview'));
const MetricsRoute = withLazy(() =>
  import('../analysis/routes/metrics/MetricsRoute').then((m) => ({
    default: m.MetricsRoute,
  })),
);
const Question = withLazy(() => import('../Question'));
const QuestionResultsRoutes = withLazy(() =>
  import('../analysis/routes/questions/QuestionResultsRoutes').then((m) => ({
    default: m.QuestionResultsRoutes,
  })),
);
const SegmentOverview = withLazy(
  () => import('../../components/dashboard/SegmentOverview'),
);
const SegmentsRoutes = withLazy(() =>
  import('../analysis/routes/segments/SegmentsRoutes').then((m) => ({
    default: m.SegmentsRoutes,
  })),
);
const TopicsRoutes = withLazy(() =>
  import('../insight/routes/topics/TopicsRoutes').then((m) => ({
    default: m.TopicsRoutes,
  })),
);

const CuratedInsightsRoute = withLazy(() =>
  import('../insight/routes/CuratedInsightsRoute/CuratedInsightsRoute').then(
    (m) => ({
      default: m.CuratedInsightsRoute,
    }),
  ),
);

const DashboardContainer = () => {
  const location = useLocation();

  return (
    <ProductTourProvider>
      <ConversationsProvider>
        <Switch>
          <Route
            path="/dashboard/experience"
            component={EmployeeExperienceRoutes}
          />
          <RestrictedRoute
            rights={['curatedInsights:read']}
            feature="curatedInsights"
            path="/dashboard/curated_insights"
            component={CuratedInsightsRoute}
          />
          <Redirect
            exact
            from="/dashboard/segment/:segmentId"
            to={{
              pathname: `/dashboard/group/engagement/segment/:segmentId`,
              search: location.search,
            }}
          />
          <RestrictedRoute
            rights={['score:driver:read']}
            path="/dashboard/segment/:segmentId/question/:id"
            component={Question}
          />
          <Route path="/dashboard/(driver|segment)" component={Driver} />
          <Route path="/dashboard/segments" component={SegmentsRoutes} />
          <RestrictedRoute
            addOn={['external_metrics']}
            path="/dashboard/external_metrics"
            rights={['externalMetric:score']}
            component={ExternalMetricsRoute}
          />
          <RestrictedRoute
            addOn="comment_topics"
            rights={[
              'comment:driver:read',
              'comment:text:read',
              'comment:value:read',
            ]}
            path="/dashboard/topics"
            component={TopicsRoutes}
          />
          <RestrictedRoute
            rights={['score:driver:read']}
            path="/dashboard/question/:id"
            component={Question}
          />
          <Route path="/dashboard/comments" component={CommentsRoutes} />
          <RestrictedRoute
            path="/dashboard/nps-summary"
            rights={[
              'comment:driver:read',
              'comment:text:read',
              'comment:value:read',
            ]}
            component={NpsSummaryRoute}
            addOn="ai_summaries"
          />

          <RestrictedRoute
            path="/dashboard/group/:group(engagement|transformation_change|diversity_inclusion|health_wellbeing|other|covid19|onboarding|exit)/drivers"
            rights={['dataset:read']}
            component={Drivers}
          />
          <RestrictedRoute
            rights={['questionScoresPage:read']}
            path="/dashboard/group/:group(engagement|transformation_change|diversity_inclusion|health_wellbeing|other|covid19|values|onboarding|exit)/questions"
            component={QuestionResultsRoutes}
          />
          <RestrictedRoute
            rights={['dataset:compare']}
            path="/dashboard/group/:group(engagement|diversity_inclusion|health_wellbeing|transformation_change|other|covid19)/segment/:segmentId"
            component={SegmentOverview}
          />
          <RestrictedRoute
            path="/dashboard/group/:group(engagement|diversity_inclusion|health_wellbeing|transformation_change|other|covid19)"
            rights={['dataset:read']}
            component={Overview}
            categoryGroupAccessType="overview"
          />
          <RestrictedRoute
            path="/dashboard/metrics*"
            rights={['usageMetrics:read']}
            component={MetricsRoute}
          />
          <Route
            exact
            path={['/dashboard/drivers', '/dashboard/questions']}
            component={() => <RedirectToAvailableRoute />}
          />
          <Route component={NotFoundScreen} />
        </Switch>
        <ConversationSlideIn />
      </ConversationsProvider>
    </ProductTourProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default DashboardContainer;
