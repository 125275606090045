import React from 'react';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Highlighter from 'react-highlight-words';

import styles from './styles.css';

const SEARCH_HIGHLIGHT_REGEX = /\*{2}([^*]+)\*{2}/g;

type Props = {
  text: string;
  topicMatches?: Array<string>;
  sensitiveMatches?: Array<string>;
};

export const HighlightedCommentText = ({
  text,
  topicMatches,
  sensitiveMatches,
}: Props) => {
  const highlightClassName: Record<string, string> = {};

  const topicSearchWords = topicMatches || [];
  topicSearchWords.forEach((word) => {
    highlightClassName[word] = styles.highlight;
  });

  const sensitiveSearchWords = sensitiveMatches || [];
  sensitiveSearchWords.forEach((word) => {
    highlightClassName[word] = styles.sensitiveHighlight;
  });

  let textToHighlight = text;
  let searchedWords: string[] = [];
  const searchHighlight = text.includes('**');
  if (searchHighlight) {
    const matches = text.match(SEARCH_HIGHLIGHT_REGEX);
    if (matches) {
      searchedWords = matches.map((w) => w.replace(/\*/g, ''));
    }

    // ensure the highlighted double asterisk gets removed, but keep single/non-highlighted ones
    searchedWords.forEach(
      (w) => (textToHighlight = textToHighlight.replace(`**${w}**`, w)),
    );
  }

  const searchWords = topicSearchWords
    .concat(sensitiveSearchWords)
    .concat(searchedWords);

  return (
    <Highlighter
      autoEscape
      highlightClassName={highlightClassName}
      searchWords={searchWords}
      textToHighlight={textToHighlight}
    />
  );
};
