import React from 'react';

import store from 'store2';

import { LinkButton } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { t } from '@peakon/shared/features/i18next/t';

import ErrorImage from '../../../../assets/images/temporary/misc/Unavailable_error-page@2x.png';
import { FullPageError } from '../../../../components/ErrorHandling/FullPageError/FullPageError';
import { assignWindowLocation } from '../../../../utils/window';
import { getSubdomainTransferHref } from '../../getSubdomainTransferHref';
import { useTransferTokenQuery } from '../queries/useTransferTokenQuery';

import styles from './styles.css';

type Props = {
  newSubdomain: string;
  currentSubdomain: string;
};

/**
 *
 * Transfers the user to the subdomain through the transfer route and transfer token, which will preserve the user's session.
 */
const TransferToSubdomain = ({ newSubdomain, currentSubdomain }: Props) => {
  const { data: transferToken, isLoading } = useTransferTokenQuery();

  if (isLoading) {
    return (
      <div
        data-test-id="transfer-token-loading"
        className={styles.loadingScreen}
      >
        <Spinner size="48" />
      </div>
    );
  }

  if (!transferToken) {
    return (
      <FullPageError
        illustration={<img src={ErrorImage} alt="" />}
        heading={t('fetch_transfer_token_error__title')}
        description={t('fetch_transfer_token_error__description')}
        withLinkToHelpCenter={false}
      >
        <LinkButton variant="primary" href="/">
          {t('generic_error_page__go_to_dashboard')}
        </LinkButton>
      </FullPageError>
    );
  }

  store.remove('auth_token');

  const redirectURL = getSubdomainTransferHref({
    href: window.location.href,
    currentSubdomain,
    newSubdomain,
    transferToken,
  });

  assignWindowLocation(redirectURL);
  return null;
};

// eslint-disable-next-line import/no-default-export
export default TransferToSubdomain;
