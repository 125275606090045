import { Set, Record } from 'immutable';

const Selection = Record<{
  total: number;
  type: 'RANGE' | 'ALL' | 'RANGE_ALL';
  range: Set<string>;
}>({
  total: 0,
  type: 'RANGE', // ALL || RANGE || RANGE_ALL
  range: Set(),
});

const selectedEmployees = (state = new Selection(), action: $TSFixMe) => {
  switch (action.type) {
    case 'FILTER_SELECT_ALL': {
      const { total } = action.data;

      return state.merge({
        type: 'ALL',
        total,
        range: Set(),
      });
    }
    case 'FILTER_SELECT_ALL_LOADED': {
      const { ids, total } = action.data;

      return new Selection({ type: 'RANGE_ALL', range: Set(ids), total });
    }
    case 'EMPLOYEE_LOAD_MORE_SUCCESS': {
      const { data: employees } = action.data;

      return state.type === 'RANGE_ALL'
        ? state.merge({
            range: state.range.concat(
              Set(
                employees.map(
                  (
                    // @ts-expect-error Parameter 'e' implicitly has an 'any' type. (ts7006)
                    e,
                  ) => e.id,
                ),
              ),
            ),
            total: state.total + employees.length,
          })
        : state;
    }
    case 'EMPLOYEE_BULK_DELETE_SUCCESS':
    case 'EMPLOYEE_FILTER_SUCCESS':
    case 'FILTER_DESELECT_ALL': {
      return new Selection();
    }
    case 'FILTER_SELECT_EMPLOYEE': {
      const { id } = action.data;

      if (state.type === 'ALL') {
        const excludedEmployees = state.range.remove(id);
        const wasRemoved = state.range.size !== excludedEmployees.size;

        return state.merge({
          range: excludedEmployees,
          total: wasRemoved ? state.total + 1 : state.total,
        });
      }

      return state.merge({
        type: 'RANGE',
        range: state.range.add(id),
        total: state.total + 1,
      });
    }
    case 'FILTER_DESELECT_EMPLOYEE': {
      const { id } = action.data;

      if (state.type === 'ALL') {
        const excludedEmployees = state.range.add(id);

        return state.merge({
          range: excludedEmployees,
          total: state.total - 1,
        });
      }

      return state.merge({
        type: 'RANGE',
        range: state.range.remove(id),
        total: state.total - 1,
      });
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default selectedEmployees;
