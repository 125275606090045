import ActionFilters from '@peakon/records/ActionFiltersRecord';

const filters = (
  state = new ActionFilters(),
  action: $TSFixMe,
): ActionFilters => {
  switch (action.type) {
    case 'ACTION_FILTERS_SET_CONTEXT': {
      const { context } = action.data;

      return state.set('context', context);
    }

    case 'ACTION_FILTERS_SORT': {
      const { sort, order } = action.data;

      return state.merge({
        sort,
        order,
      });
    }

    case 'ACTION_FILTERS_SET_STATUS': {
      const { status } = action.data;

      return state.set('status', status);
    }

    case 'ACTION_FILTERS_SET_DEADLINE': {
      const { deadline } = action.data;

      return state.set('deadline', deadline);
    }

    case 'ACTION_FILTERS_SET_ACCESS': {
      const { access } = action.data;

      return state.set('access', access);
    }

    case 'ACTION_FILTERS_SET_CATEGORY': {
      const { category } = action.data;

      return state.set('category', category);
    }

    case 'ACTION_FILTERS_SET_CATEGORY_GROUP': {
      const { categoryGroup } = action.data;

      return state.set('categoryGroup', categoryGroup);
    }

    case 'ACTION_FILTERS_SET_ABOVE_ANONYMITY': {
      const { aboveAnonymity } = action.data;

      return state.set('aboveAnonymity', aboveAnonymity);
    }

    case 'ACTION_FILTERS_RESET': {
      return new ActionFilters();
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default filters;
