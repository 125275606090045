import React, { useMemo } from 'react';

import get from 'lodash/get';
import times from 'lodash/times';
import { InView } from 'react-intersection-observer';

import { NotificationsNotificationsBellOutlineIcon as NotificationIcon } from '@peakon/bedrock/icons/system';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Heading5 } from '@peakon/bedrock/react/typography';
import { Menu, Tooltip } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { useShellContext } from '@peakon/shell';

import { EmptyNotification } from './Empty';
import { Notification } from './Notification';
import { useNotifications } from './useNotifications';
import ErrorBoundary from '../../../ErrorHandling/ShellErrorBoundary';

import styles from './styles.css';
import otherStyles from '../styles.css';

const NotificationsDropdown = ({ employeeId }: { employeeId: string }) => {
  const {
    notifications,
    unread,
    isInitialLoading,
    isEmpty,
    isLoadingMore,
    hasMore,
    loadMore,
    onToggleMenu,
  } = useNotifications({ employeeId });

  const hasUnread = unread > 0;

  return (
    <Menu
      id="notifications"
      onStateChange={async (changes) => {
        if (changes.hasOwnProperty('isOpen')) {
          await onToggleMenu(changes.isOpen ?? false);
        }
      }}
    >
      <Menu.Target
        aria-label={
          hasUnread
            ? t('multi_product__a11y__notifications_unread', {
                replace: {
                  count: unread,
                },
              })
            : t('multi_product__a11y__notifications')
        }
      >
        <div>
          <Tooltip.Core
            trigger="mouseenter focus"
            target={
              <div
                className={otherStyles.iconLink}
                data-test-id="notification-icon"
              >
                <NotificationIcon />
                {hasUnread && (
                  <div className={styles.badge}>
                    {unread > 99 ? '99+' : unread}
                  </div>
                )}
              </div>
            }
          >
            <Tooltip.Content>
              {t('multi_product__a11y__notifications')}
            </Tooltip.Content>
          </Tooltip.Core>
        </div>
      </Menu.Target>
      <Menu.Popover
        className={styles.menu}
        placement="bottom"
        strategy="fixed"
        maxHeight={420}
      >
        <div className={styles.header}>
          <Heading5 level={2}>{t('multi_product__notifications')}</Heading5>
        </div>
        {isInitialLoading ? (
          times(5).map((i) => (
            // @ts-expect-error TS(2322): Type '{ component: ForwardRefExoticComponent<Props... Remove this comment to see the full error message
            <Menu.Item key={i} component={Notification} disabled isLoading />
          ))
        ) : isEmpty ? (
          <Menu.Item component={EmptyNotification} value="empty" />
        ) : (
          notifications.map((notification) => (
            <Menu.ItemLink
              key={notification?.id}
              // @ts-expect-error TS(2322): Type '{ children: any; component: ForwardRefExotic... Remove this comment to see the full error message
              component={Notification}
              notification={notification}
              value={notification?.id}
            >
              {notification?.id}
            </Menu.ItemLink>
          ))
        )}

        <InView
          onChange={async (visible: boolean) => {
            if (visible && !isLoadingMore && hasMore) {
              await loadMore();
            }
          }}
        />

        {isLoadingMore && (
          <div role="none" className={styles.loader}>
            <Spinner />
          </div>
        )}
      </Menu.Popover>
    </Menu>
  );
};

// This component is necessary to conditionaly call the useRealtimeChannel
const NotificationsDropdownWrapper = () => {
  const { session } = useShellContext();

  const { hasNotificationRight } = useMemo(() => {
    const rights = get(session, 'data.attributes.rights', []);

    return {
      // @ts-expect-error TS2345: Argument of type 'string' is not assignable to parameter of type 'never'.
      hasNotificationRight: rights.includes('notification:read'),
    };
  }, [session]);

  const employeeId = get(session, 'data.relationships.employee.id');

  if (!hasNotificationRight || !employeeId) {
    return null;
  }

  return (
    <ErrorBoundary>
      <NotificationsDropdown employeeId={employeeId} />
    </ErrorBoundary>
  );
};

// eslint-disable-next-line import/no-default-export
export default NotificationsDropdownWrapper;
