import { List, Map } from 'immutable';

import { Comment, Employee } from '@peakon/records';
import Acknowledgement from '@peakon/records/AcknowledgementRecord';

import createPagination from '../utils/createPagination';

const overview = (state = List<Comment>(), action: $TSFixMe): List<Comment> => {
  switch (action.type) {
    case 'COMMENTS_LIST_BY_CONTEXT_SUCCESS': {
      const { data: comments } = action.data;

      return List(comments.map(Comment.createFromApi));
    }

    case 'COMMENTS_LOAD_MORE_BY_CONTEXT_SUCCESS': {
      const { data: comments } = action.data;

      return state.concat(List(comments.map(Comment.createFromApi)));
    }

    case 'COMMENT_MANAGERS_LIST_LOADING': {
      const { id } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) =>
        comment.set('isLoadingManagers', true),
      );
    }

    case 'COMMENT_MANAGERS_LIST_SUCCESS': {
      const { id, data } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) =>
        comment.merge({
          isLoadingManagers: false,
          managers: List(data.map(Employee.createFromApi)),
        }),
      );
    }

    case 'COMMENT_DELETE_SUCCESS': {
      const { id } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.delete(index);
    }

    case 'COMMENT_ACKNOWLEDGE_SUCCESS': {
      const { id, data } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) =>
        comment.merge({
          acknowledgement: Acknowledgement.createFromApi(
            data.relationships.acknowledgement,
          ),
          acknowledgementCounts: Map(data.attributes.acknowledgementCounts),
        }),
      );
    }

    case 'COMMENT_TRANSLATE_SUCCESS': {
      const { id, translation } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) =>
        comment.merge({
          translation,
          translated: true,
        }),
      );
    }

    case 'COMMENT_REVERT_TRANSLATE': {
      const { id } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) => comment.set('translated', false));
    }

    case 'COMMENT_MARK_SENSITIVE_SUCCESS': {
      const { id } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) => comment.set('sensitive', true));
    }

    case 'COMMENT_MARK_NOT_SENSITIVE_SUCCESS': {
      const { id } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) => comment.set('sensitive', false));
    }

    case 'COMMENTS_CONVERSATION_MESSAGE_ADDED': {
      const { id } = action.data;

      const index = state.findIndex((comment) => comment.id === id);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) =>
        comment.set('messageCount', (comment.messageCount ?? 0) + 1),
      );
    }

    case 'COMMENT_SET_ACTIVE': {
      const { id } = action.data;

      return state.update((comments) =>
        comments.map((comment) => {
          if (comment.id === id) {
            return comment.set('active', true);
          }

          return comment.active ? comment.set('active', false) : comment;
        }),
      );
    }

    case 'COMMENTS_MARK_AS_READ_SUCCESS': {
      return state.update((comments) =>
        comments.map((comment) => {
          return comment.set('new', false);
        }),
      );
    }

    case 'COMMENT_SEND_SEMANTIC_TOPICS_FEEDBACK_SUCCESS':
    case 'COMMENT_SEND_SEMANTIC_SEARCH_FEEDBACK_SUCCESS': {
      const { commentId, isBiased, isBiasedComment, isRelevant } = action.data;

      const index = state.findIndex((comment) => comment.id === commentId);
      if (index === -1) {
        return state;
      }

      return state.update(index, (comment) =>
        comment.merge({
          commentFeedback: {
            isRelevant,
            isBiased,
            isBiasedComment,
          },
        }),
      );
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default createPagination<Comment, { searchQueryLocale?: string }>(
  overview,
  {
    startTypes: ['COMMENTS_LIST_BY_CONTEXT'],
    appendTypes: ['COMMENTS_LOAD_MORE_BY_CONTEXT'],
    resetTypes: ['COMMENTS_FLUSH'],
    removeTypes: ['COMMENT_DELETE'],
    defaultSkip: false,
  },
);
