import { z } from 'zod';

const notificationItemBaseAttributesSchema = z.enum([
  'actionCreated',
  'actionsDue',
  'comments',
  'criticalSegment',
  'criticalDriver',
  'criticalSegmentRemoved',
  'criticalDriverRemoved',
  'engagementChange',
  'scoresChange',
  'inputs',
  'message',
  'npsSummaryAvailable',
  'presentation',
  'priorityDriver',
  'priorityDriverAccepted',
  'priorityDriverRemoved',
  'priorityDriverRejected',
  'prioritySegment',
  'sensitiveComment',
  'valueComments',
  'teamSuggestionAdded',
]);

const baseContextSchema = z.object({
  attributes: z
    .object({
      abbreviation: z.string(),
      level: z.string(),
      logo: z.string().nullable(),
      name: z.string(),
    })
    .optional(),
  id: z.string(),
  type: z.string(),
});

type Relationships = {
  employee?: $TSFixMe;
  attribute?: $TSFixMe;
  context?:
    | (z.infer<typeof baseContextSchema> & {
        relationships?: Relationships;
      })
    | null;
};

const relationshipsSchema: z.ZodType<Relationships> = z.object({
  employee: z.any().nullable(),
  attribute: z.any().nullable(),
  context: baseContextSchema
    .extend({
      relationships: z.lazy(() => relationshipsSchema).optional(),
    })
    .nullable()
    .optional(),
});

export const notificationItemSchema = z.object({
  type: z.enum(['notifications', 'contexts']),
  id: z.string(),
  attributes: z.object({
    type: notificationItemBaseAttributesSchema,
    createdAt: z.string(),
    readAt: z.string().nullable().optional(),
    status: z.string(),
    text: z.string(),
    url: z.string(),
    viewedAt: z.string().nullable(),
    data: z
      .object({
        classification: z.string().optional(),
        group: z.string().optional(),
        standard: z.string().optional(),
        mentioned: z.boolean().optional(),
        sign: z.number().optional(),
        driver: z.string().nullable().optional(),
        subdriver: z.string().nullable().optional(),
      })
      .optional(),
  }),
  relationships: relationshipsSchema,
});

type NotificationItem = z.infer<typeof notificationItemSchema>;

export type NotificationItemBaseAttributesType = z.infer<
  typeof notificationItemBaseAttributesSchema
>;

export type NotificationItemBaseAttributesData =
  NotificationItem['attributes']['data'];

export type NotificationItemContext =
  NotificationItem['relationships']['context'];
