import React, { useEffect, useState } from 'react';

import { t } from '@peakon/shared/features/i18next/t';
import PeakonStatus from '@peakon/status';

import { useShellContext } from '../../context/ShellContext';

import styles from './styles.css';

const INTERVAL_DEFAULT = 15 * 60 * 1000;
const INTERVAL_OUTAGE = 60 * 1000;

const STATUS_MAP = {
  OPERATIONAL: 'operational',
  UNDER_MAINTENANCE: 'under_maintenance',
  DEGRADED_PERFORMANCE: 'degraded_performance',
  PARTIAL_OUTAGE: 'partial_outage',
  MAJOR_OUTAGE: 'major_outage',
};

const SystemStatusChecker = () => {
  const { statusPageHost, onToggleOutage } = useShellContext();
  const [currentStatus, setCurrentStatus] = useState(STATUS_MAP.OPERATIONAL);

  const handleStatusChange = (status: string) => {
    setCurrentStatus(status);

    if (status === STATUS_MAP.MAJOR_OUTAGE) {
      // let the system alert bar know
      onToggleOutage();
    }
  };

  useEffect(() => {
    const peakonStatus = new PeakonStatus(statusPageHost, ['Web', 'Api']);

    let interval: ReturnType<typeof setInterval>;

    const check = async () => {
      try {
        const status = await peakonStatus.check();

        if (
          status === STATUS_MAP.MAJOR_OUTAGE &&
          currentStatus !== STATUS_MAP.MAJOR_OUTAGE
        ) {
          handleStatusChange(status);

          clearInterval(interval);
          interval = setInterval(check, INTERVAL_OUTAGE);
        } else {
          if (
            currentStatus === STATUS_MAP.MAJOR_OUTAGE &&
            status !== STATUS_MAP.MAJOR_OUTAGE
          ) {
            handleStatusChange(status);
          }

          clearInterval(interval);
          interval = setInterval(check, INTERVAL_DEFAULT);
        }
      } catch {
        // noop
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
    check();
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentStatus === STATUS_MAP.OPERATIONAL) {
    return null;
  }

  return (
    <div className={styles.root}>
      <strong>{t('status__major_outage__text')}</strong>
      <a href={statusPageHost} rel="noopener noreferrer" target="_blank">
        {t('status__major_outage__link')}
      </a>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default SystemStatusChecker;
