import React from 'react';

import classnames from 'classnames';
import escape from 'lodash/escape';

import { Box, Inline, Stack } from '@peakon/bedrock/react/layout';
import { BodyText } from '@peakon/bedrock/react/typography';
import { Grid } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import xss from '@peakon/shared/utils/xss';

import { EmployeeAvatar } from './EmployeeAvatar';
import { useRelativeTime } from './useRelativeTime';
import { ConversationKind } from '../../../context/ConversationsContext';
import { type Message } from '../../../queries/conversations/types/message';

import styles from './styles.css';

const markdownMention = (text: string) => {
  return text.replace(/\*\*(@\S(?:[\s\S]*?\S)?)\*\*/g, '<span>$1</span>');
};

export type ConversationMessageProps = {
  currentEmployeeName?: string | null;
  animated?: boolean;
  message: Omit<Message, 'animated'>;
};

const ConversationMessage = ({
  message,
  animated = false,
  currentEmployeeName = message.employee?.name,
}: ConversationMessageProps) => {
  const timeAgo = useRelativeTime(message.createdAt);

  const { isCurrentUser, kind, anonymous, employee, text, createdAt } = message;
  const isNote = kind === ConversationKind.note;

  return (
    <div
      className={classnames({
        [styles.end]: isCurrentUser || isNote,
        [styles[message.kind]]: message.kind,
        [styles.animated]: animated,
      })}
      data-test-id={`conversation-message-${message.kind}`}
    >
      <Grid>
        <Grid.Row>
          <Grid.Col xs={10} offsetXs={isCurrentUser || isNote ? 2 : 0}>
            <Stack spacing={4}>
              {isCurrentUser && anonymous && (
                <BodyText size="small" bold textAlign="end">
                  {t('conversations__reply__you')}{' '}
                  <BodyText size="small" as="span" variant="hint">
                    {t('employee_identity_not_displayed')}
                  </BodyText>
                </BodyText>
              )}
              {!isCurrentUser && anonymous && (
                <BodyText size="small" textAlign="end">
                  {t('employee_identity_not_displayed')}
                </BodyText>
              )}
              {!anonymous && (
                <BodyText
                  size="small"
                  bold
                  textAlign={isCurrentUser ? 'end' : 'start'}
                >
                  {isCurrentUser ? currentEmployeeName : employee?.name}
                </BodyText>
              )}
              <Inline
                spacing={8}
                alignInline={isCurrentUser ? 'end' : 'start'}
                noWrap
              >
                {!isCurrentUser && (
                  <div className={styles.avatar}>
                    <EmployeeAvatar anonymous={anonymous} employee={employee} />
                  </div>
                )}
                <div className={styles.textWrapper}>
                  <Stack spacing={4}>
                    <div className={styles.text}>
                      <Box paddingBlock={12} paddingInline={16}>
                        <div // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: xss.process(markdownMention(escape(text))),
                          }}
                        />
                      </Box>
                    </div>
                    <BodyText
                      as="time"
                      dateTime={createdAt}
                      size="small"
                      variant="hint"
                    >
                      {isNote
                        ? t('conversation__message__sent_at__note', {
                            replace: { time_ago: timeAgo },
                          })
                        : t('conversation__message__sent_at__reply', {
                            replace: { time_ago: timeAgo },
                          })}
                    </BodyText>
                  </Stack>
                </div>
                {isCurrentUser && (
                  <div className={styles.avatar}>
                    <EmployeeAvatar anonymous={anonymous} employee={employee} />
                  </div>
                )}
              </Inline>
            </Stack>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ConversationMessage;
