import React from 'react';

import classnames from 'classnames';

import { Select } from '@peakon/bedrock/react/form';
import type { Options } from '@peakon/localization';
import { t } from '@peakon/shared/features/i18next/t';
import { validatedEnvConfig } from '@peakon/shared/utils/env';

import { getLocales } from './utils/getLocales';

import styles from './styles.css';

type Props = {
  isFilter?: boolean;
  localizationOptions?: Options;
  selected?: string | null;
  onSelect: (localeId: string) => void;
  localizedNames?: boolean;
  disabled?: boolean;
  id?: string;
  label?: string;
  placeholder?: string;
  defaultValueLabel?: string;
  isAbsolutePosition?: boolean;
  forcedLocales?: string[];
  highlightSurveyOnly?: boolean;
};

export function LanguageSelector({
  isFilter,
  localizationOptions,
  selected,
  onSelect,
  localizedNames,
  disabled,
  id,
  label,
  placeholder,
  defaultValueLabel,
  isAbsolutePosition,
  forcedLocales,
  highlightSurveyOnly,
}: Props) {
  const availableLocales = getLocales(
    localizedNames ?? true,
    localizationOptions ?? {
      employee: true,
      disabled: [],
      includePrerelease: validatedEnvConfig.enablePrereleaseLanguages,
    },
    isFilter,
  );

  const locales =
    forcedLocales && forcedLocales.length > 0
      ? availableLocales.filter((locale) => {
          return forcedLocales.includes(locale.value);
        })
      : availableLocales;

  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  label = label || t('account__a11y__locale__placeholder');
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  placeholder = isFilter
    ? placeholder || t('filters__select__placeholder')
    : placeholder || t('account__locale__placeholder');
  // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  defaultValueLabel = isFilter
    ? null
    : defaultValueLabel || t('account__locale__placeholder');

  return (
    <div
      data-test-id="language-selector"
      className={classnames({
        [styles.fixed]: isAbsolutePosition ?? false,
      })}
    >
      <Select
        id={id}
        label={label}
        hideLabel
        value={selected ?? ''}
        onChange={(e) => {
          onSelect(e.target.value);
        }}
        disabled={disabled}
      >
        <Select.Option value="" disabled={isFilter}>
          {defaultValueLabel ?? placeholder}
        </Select.Option>
        {locales.map(({ value, label: localesLabel, full }) => (
          <Select.Option key={value} value={value}>
            {highlightSurveyOnly && !full
              ? t('account__locale_survey_only_label', {
                  replace: { language: localesLabel },
                })
              : localesLabel}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
