import { Map, Record, fromJS, List, type StaticMap } from 'immutable';
import { z } from 'zod';

import { type ScoreMode } from '@peakon/shared/types/ScoreMode';
import { validateData } from '@peakon/shared/utils/validateData/validateData';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import CategoryRecord from './CategoryRecord';
import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';

const highlightTypes = z.union([
  z.literal('stable'),
  z.literal('trending'),
  z.literal('large'),
  z.literal('highScore'),
  z.literal('lowScore'),
]);
type Highlights = z.infer<typeof highlightTypes>;
const highlightSchema = z
  .array(highlightTypes)
  .nullable()
  .optional()
  .catch(undefined);
export const NPSSchema = z.object({
  promoters: z.number(),
  passives: z.number(),
  detractors: z.number(),
  score: z.number().optional(),
});
type NPSSchemaType = z.infer<typeof NPSSchema>;

// FIXME: Update schema once https://github.com/peakon/api/pull/18716 is merged and deployed
export const favorableSchema = z.object({
  total: z.number().optional(), // FIXME: Deprecated
  favorables: z.number().optional(), // FIXME: Non-optional
  neutrals: z.number().optional(), // FIXME: Non-optional
  unfavorables: z.number().optional(), // FIXME: Non-optional
  score: z.number().optional(),
});

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  matches: z.array(z.string()).optional(),
  name: z.string(),
  size: z.number().optional(),
  bundle: z.string().nullable().optional(),
  mean: z.number().optional(),
  summary: z.string().nullable().optional(),
  driver: z.string().nullable().optional(),
  count: z.number().optional(),
  topic: z.string().optional(),
  nps: NPSSchema.optional(),
  favorable: favorableSchema.optional(),
  score: z.number().optional(),
  locale: z.string().nullable().optional(),
  category: z.object({}).passthrough().optional(),
  highlights: highlightSchema,
  group: z.string().optional(),

  /**
   *
   * NOTE: These don't come from the backend
   *
   * These are set directly in our reducers to handle per comment translations,
   * so we still need them here for types not to complain.
   *
   */

  translated: z.boolean().optional(),
  translatedName: z.string().optional(),
  translatedSummary: z.string().optional(),
});

type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    category?: CategoryRecord;
    matches?: List<string>;
    highlights: List<Highlights>;
    nps?: StaticMap<NPSSchemaType>;
  }
>;

class TopicRecord extends Record<Schema>({
  bundle: undefined,
  category: undefined,
  count: 1,
  group: undefined,
  highlights: List<Highlights>(),

  /**
   *
   * NOTE: The id is required
   *
   * The `id` is required but of course we don't have a valid one in the default attributes
   *
   */

  id: '',
  locale: undefined,
  matches: undefined,
  mean: undefined,
  name: '',

  /**
   *
   * NOTE: This is now optional in the type
   *
   * Even though we set this to optional in the types we don't quite want to
   * remove this default value yet as we might have some hidden usage of it that
   * relies on it being there even with empty values :(
   *
   */

  nps: Map<NPSSchemaType>(),
  score: undefined,
  summary: undefined,
  topic: undefined,
  translated: false,
  translatedName: undefined,
  translatedSummary: undefined,
}) {
  constructor(
    /**
     *
     * NOTE: We remove "required" from the attributes that have default values
     * here instead of in the schema to make sure the topic type propagates correctly (as in
     * `topic.highlights` will always be a list) but are not required when you
     * create the record with `new TopicRecord()` since they are not always there.
     *
     */

    props: Omit<Schema, 'highlights' | 'nps'> & {
      highlights?: Array<Highlights>;
      nps?: NPSSchemaType;
    },
  ) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'TopicRecord',
      defaultValues: {
        highlights: List(),
      },
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'TopicRecord',
      defaultValues: {
        highlights: List(),
      },
      log: [
        {
          environment: 'local',
          logLevel: 'error',
        },
        {
          environment: 'staging',
          logLevel: 'warning',
        },
        {
          environment: 'production',
          logLevel: 'warning',
        },
      ],
    });
    super(
      // @ts-expect-error - Argument of type 'Omit<Schema, "highlights"> & { highlights?: ("stable" | "trending" | "large" | "highScore" | "lowScore")[] | undefined; }' is not assignable to parameter of type 'Schema'.
      props,
    );
  }

  translate(name: string, summary: string) {
    return this.merge({
      translated: true,
      translatedName: name,
      translatedSummary: summary,
    });
  }

  getScore(mode: ScoreMode) {
    if (mode === 'nps') {
      return this.nps?.get('score');
    }

    return this.mean;
  }

  static createFromApi(data: $TSFixMe) {
    const {
      relationships: { category },
    } = data;

    const highlights = validateData(
      data.attributes.highlights,
      highlightSchema,
      {
        errorMessagePrefix: 'TopicRecord.highlights',
      },
    );

    return new TopicRecord(
      fromJS({
        id: data.id,
        ...data.attributes,
        highlights: highlights ? List(highlights) : List(),
        category: category ? CategoryRecord.createFromApi(category) : undefined,
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default TopicRecord;
