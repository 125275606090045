import { useEffect, useRef } from 'react';

function usePrevious<TValue>(
  value: TValue,
  { defaultValue }: { defaultValue?: TValue } = {},
) {
  const ref = useRef<TValue | undefined>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // eslint-disable-next-line react-compiler/react-compiler
  if (typeof ref.current === 'undefined') {
    return defaultValue;
  }

  // eslint-disable-next-line react-compiler/react-compiler
  return ref.current;
}

// eslint-disable-next-line import/no-default-export
export default usePrevious;
