import ErrorReporter from '@peakon/error-reporter';

/**
 * NOTE: Need to use ENV from global here scope to avoid circular dependency
 */
export const errorReporter = new ErrorReporter({
  // eslint-disable-next-line no-restricted-globals
  accessToken: ENV.rollbar ? ENV.rollbar.client : '',
  // eslint-disable-next-line no-restricted-globals
  environment: ENV.clusterEnv,
  // eslint-disable-next-line no-restricted-globals
  version: ENV.version,
  // @ts-expect-error Type '(payload: Dictionary) => Dictionary' is not assignable to type
  transform: (payload) => {
    // https://docs.rollbar.com/docs/source-maps#using-source-maps-on-many-domains
    // @ts-expect-error 'payload.body' is of type 'unknown'
    const trace = payload.body.trace;
    const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+peakon\.com(.*)/;
    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i++) {
        const filename = trace.frames[i].filename;
        if (filename) {
          const m = filename.match(locRegex);
          // Be sure that the minified_url when uploading includes 'dynamichost'
          trace.frames[i].filename = `${m[1]}://dynamichost${m[2]}`;
        }
      }
    }
    return payload;
  },
});

export type ErrorReporterType = typeof errorReporter;
