import React, { Fragment, useEffect } from 'react';

import classnames from 'classnames';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Tooltip as TippyTooltip } from 'react-tippy';

import { NavigationBasicNavigationChevronRightIcon as ArrowRight } from '@peakon/bedrock/icons/system';
import { MOBILE_MAX_WIDTH } from '@peakon/shared/constants/breakpoints';
import { t } from '@peakon/shared/features/i18next/t';

import MobileTooltip from './MobileTooltip';

import styles from './styles.css';
import 'react-tippy/dist/tippy.css';

type BaseProps = {
  children: React.ReactNode;
};

type HeaderProps = {
  isMobile?: boolean;
  link?: string;
  title?: string | React.ReactNode;
};

type MobileTooltipWrapperProps = {
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  disabled?: boolean;
  hideBorder?: boolean;
  link?: string;
  title?: string | React.ReactNode;
};

const defaultProps = {
  animateFill: false,
  animation: 'shift',
  delay: [100, 200],
  interactive: true,
  interactiveBorder: 10,
  theme: 'peakon',
  trigger: 'mouseenter',
};

type TooltipProps = MobileTooltipWrapperProps &
  typeof defaultProps & {
    forceDesktop?: boolean;
    position?: string;
    popperOptions?: Record<string, unknown>;
    style?: Record<string, string | number | undefined>;
    tabIndex?: number;
  };

const Section = ({ children }: BaseProps) => (
  <div className={styles.section}>{children}</div>
);

const Subtitle = ({ children }: BaseProps) => (
  <div className={styles.subtitle}>{children}</div>
);

const Content = ({ children, id }: BaseProps & { id?: string }) => (
  <div className={styles.content} id={id}>
    {children}
  </div>
);

const Header = ({ isMobile, title, link }: HeaderProps) => (
  <div
    className={classnames(styles.header, {
      [styles.mobile]: isMobile,
    })}
  >
    <div className={styles.title}>{title}</div>
    {link && (
      <a
        className={styles.link}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('read_more')}
        <ArrowRight className={styles.linkArrow} />
      </a>
    )}
  </div>
);

const MobileTooltipWrapper = ({
  className,
  hideBorder,
  disabled,
  children,
  title,
  link,
  content,
}: MobileTooltipWrapperProps) => (
  <MobileTooltip
    toggler={
      <span
        className={classnames(styles.toggler, className, {
          [styles.noBorder]: hideBorder,
          [styles.disabled]: disabled,
        })}
        data-test-id="dashboard-mobile-tooltip"
      >
        {children}
      </span>
    }
  >
    {title && (
      // @ts-expect-error TS(2339): Property 'Header' does not exist on type 'typeof M... Remove this comment to see the full error message
      <MobileTooltip.Header>
        <Header isMobile title={title} link={link} />
        {/* @ts-expect-error TS(2339): Property 'Header' does not exist on type 'typeof M... Remove this comment to see the full error message */}
      </MobileTooltip.Header>
    )}
    {/* @ts-expect-error TS(2339): Property 'Content' does not exist on type 'typeof ... Remove this comment to see the full error message */}
    <MobileTooltip.Content>
      <div className={styles.main}>{content}</div>
      {/* @ts-expect-error TS(2339): Property 'Content' does not exist on type 'typeof ... Remove this comment to see the full error message */}
    </MobileTooltip.Content>
  </MobileTooltip>
);

const Tooltip = (props: TooltipProps) => {
  let tooltipRef: $TSFixMe = null;

  useEffect(() => {
    // remove any leftovers on unmount
    return function cleanup() {
      Tooltip.renderedTooltips = [];
    };
  });

  const {
    children,
    className,
    content,
    hideBorder,
    link,
    title,
    forceDesktop,
    trigger,
    ...tippyTooltipProps
  } = props;

  if (!forceDesktop && window.innerWidth <= MOBILE_MAX_WIDTH) {
    return <MobileTooltipWrapper {...props} />;
  }

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      tooltipRef.hideTooltip();
    }
  };

  return (
    <TippyTooltip
      ref={(ref: $TSFixMe) => {
        if (ref) {
          // eslint-disable-next-line react-compiler/react-compiler
          tooltipRef = ref;
        }
      }}
      arrow
      className={classnames(styles.toggler, className, {
        [styles.noBorder]: hideBorder,
        [styles.disabled]: props.disabled,
      })}
      html={
        <Fragment>
          {title && <Header title={title} link={link} />}
          <div className={styles.main}>{content}</div>
        </Fragment>
      }
      onHide={() => {
        window.document.removeEventListener('keydown', onKeyDown);
      }}
      onShow={() => {
        Tooltip.renderedTooltips.forEach((rTooltip) => rTooltip.hideTooltip());
        Tooltip.renderedTooltips = [tooltipRef];

        window.document.addEventListener('keydown', onKeyDown);
      }}
      inertia
      {...tippyTooltipProps}
      trigger={trigger}
    >
      {children}
    </TippyTooltip>
  );
};

Tooltip.defaultProps = defaultProps;

Tooltip.Section = Section;
Tooltip.Subtitle = Subtitle;
Tooltip.Content = Content;
// @ts-expect-error TS(7008): Member 'renderedTooltips' implicitly has an 'any[]... Remove this comment to see the full error message
Tooltip.renderedTooltips = [];

// eslint-disable-next-line import/no-default-export
export default Tooltip;
